import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import styled from "@emotion/styled";
import { MenuItem, menuItemClasses } from "@kaltura/ds-react-components";
import { Download24Icon, Mail24Icon } from "@kaltura/ds-react-icons";
import { baseUrl, postKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { AddToCalendarOptionsType, ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useToastsContext } from "@kaltura/mediaspace-shared-contexts";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

export interface AddToCalendarMenuItemsProps {
    entryId: string;
    categoryId?: number;
    color?: "default" | "white";
    analyticsValue: { location: string; module?: string };
    onItemClicked?: (itemType: AddToCalendarOptionsType) => void;
    ks?: string;
}

export const StyledMenuItem = styled(
    MenuItem,
    {
        shouldForwardProp: (prop) => prop !== "color",
    }
)<{ color?: "default" | "white" }>(({ theme, color }) => ({
    ...(color === "white" && { color: theme.palette.common.white }),
    "&:hover, &:focus, &:active, &:visited": {
        ...(color === "white" && { color: theme.palette.common.white }),
    },
    [`& .${menuItemClasses.title}`]: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
    },
}));

export const AddToCalendarMenuItems = ({
    entryId,
    categoryId,
    onItemClicked,
    color = "default",
    analyticsValue,
    ks,
}: AddToCalendarMenuItemsProps) => {
    const { showToast } = useToastsContext();
    const config = useConfig();
    const showEmailOption = config.calendar?.showEmailOption ?? false;
    const calendarAppointmentUrl = config.calendar?.getAppointmentUrl;

    const sendCalendarEmail = async () => {
        const url = "/default/calendar/send-calendar-email";

        try {
            const result = await postKmsData(`${baseUrl}${url}`, { entryId, ks });
            if (result.success) {
                showToast({ severity: "success", message: result.message });
                onItemClicked?.(AddToCalendarOptionsType.EMAIL);
            }
            else {
                if (result.redirect) {
                    window.location.href = `${result.redirect}?ref=${baseUrl}${encodeURIComponent(window.location.pathname + window.location.search)}`;
                    return;
                }
                showToast({ severity: "error", message: result.error });
            }
        }
        catch (e) {
            showToast({ severity: "error", message: translate("Error sending calendar email") });
        }
    };

    const sendButtonAnalytics = useButtonAnalytics();
    const handleMenuItemClick = (type: AddToCalendarOptionsType) => {
        const buttonType =
            type === AddToCalendarOptionsType.EMAIL ? ButtonClickAnalyticsType.SEND : ButtonClickAnalyticsType.DOWNLOAD;
        sendButtonAnalytics(
            `Add to calendar - ${analyticsValue.location} - ${type}${analyticsValue.module ? ` - ${analyticsValue.module}` : ""}`,
            buttonType,
            entryId
        );

        if (type !== AddToCalendarOptionsType.EMAIL) {
            onItemClicked?.(type);
            const params = "/entryid/" + entryId + (categoryId ? `/categoryId/${categoryId}` : "") + "/cal/" + type;
            window.open(baseUrl + calendarAppointmentUrl + params);
        }
        else {
            sendCalendarEmail();
        }
    };

    return (
        <>
            {showEmailOption && (
                <StyledMenuItem
                    component="a"
                    color={color}
                    onClick={() => handleMenuItemClick(AddToCalendarOptionsType.EMAIL)}
                >
                    <Mail24Icon /> {translate("Send by email")}
                </StyledMenuItem>
            )}
            <StyledMenuItem
                component="a"
                color={color}
                onClick={() => handleMenuItemClick(AddToCalendarOptionsType.OUTLOOK)}
            >
                <Download24Icon /> {translate("Outlook calendar")}
            </StyledMenuItem>
            <StyledMenuItem
                component="a"
                color={color}
                onClick={() => handleMenuItemClick(AddToCalendarOptionsType.GOOGLE)}
            >
                <Download24Icon /> {translate("Google calendar")}
            </StyledMenuItem>
        </>
    );
};
