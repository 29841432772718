import styled from "@emotion/styled";
import { useState } from "react";
import { Link } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { CategoryBubbleType } from "./CategoryBubble";
import { CategoryBubbles } from "../../media-categories/category-bubbles/CategoryBubbles";

export interface CategoryBubblesProps {
    /**
     * list of categories to show
     */
    categories?: CategoryBubbleType[];
    entryIds?: string[];
    onDelete?: (categoryId: number) => void;
    isCollapsable: boolean;
    className?: string;
    isBulk?: boolean;
}

const StyledCategoryBubbles = styled("div")({
    width: "100%",
});

const CategoriesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    rowGap: theme.spacing(1),
    flexWrap: "wrap",
}));

const LessButton = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    fontSize: theme.typography.fontSize,
    marginTop: theme.spacing(1),
}));

/**
 * CategoryBubblesContainer component wraps the CategoryBubbles component,
 * providing optional expand/collapse functionality and analytics.
 */
export function CategoryBubblesContainer({
    categories = [],
    entryIds = [],
    onDelete,
    isCollapsable,
    className = "",
    isBulk,
}: CategoryBubblesProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isShowLessVisible, setIsShowLessVisible] = useState(false);

    const sendButtonAnalytics = useButtonAnalytics();

    const entryId = entryIds?.length === 1 ? entryIds[0] : undefined;

    const toggleCollapse = () => {
        sendButtonAnalytics(
            `Publish - all publish boxes category list - ${!isExpanded ? "Expand" : "Collapse"} categories ${isBulk ? "(bulk)" : ""}`,
            ButtonClickAnalyticsType.EXPAND,
            entryId
        );
        setIsShowLessVisible(!isExpanded);
        setIsExpanded((prev) => !prev);
    };

    const isTruncated = isCollapsable && !isExpanded;

    const onBubblesLineCountChange = (isSingleLine: boolean) => {
        if (isExpanded) {
            setIsShowLessVisible(!isSingleLine);
        }
    };

    return (
        !!categories.length && (
            <StyledCategoryBubbles className={className}>
                <>
                    <CategoriesContainer>
                        <CategoryBubbles
                            categories={categories}
                            onDelete={onDelete}
                            isTruncated={isTruncated}
                            onExpand={toggleCollapse}
                            onBubblesLineCountChange={onBubblesLineCountChange}
                        />
                    </CategoriesContainer>
                    {isShowLessVisible && (
                        <LessButton component="button" onClick={toggleCollapse}>
                            {translate("Show less")}
                        </LessButton>
                    )}
                </>
            </StyledCategoryBubbles>
        )
    );
}
