import styled from "@emotion/styled";
import { Integrations24Icon } from "@kaltura/ds-react-icons";

/**
 * Error indication for ErrorBoundary
 */
export const ErrorBoundaryFallback = () => {
    return (
        <StyledErrorBoundary>
            <Integrations24Icon />
        </StyledErrorBoundary>
    );
};

const StyledErrorBoundary = styled("div")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
}));
