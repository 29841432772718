import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import FormLabelWithDescription from "./../form/form-label-with-description/FormLabelWithDescription";
import { FormHelperText } from "@kaltura/ds-react-components";

export const StyledFormLabel = styled(FormLabelWithDescription)(({ theme }) => ({
    [`&`]: {
        marginBottom: theme.spacing(1),
    },
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    marginTop: 0,
    marginBottom: theme.spacing(1),
}));

export const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        display: "block",
    },
}));
