import styled from "@emotion/styled";
import clsx from "clsx";
import { Tooltip, Initials, Typography } from "@kaltura/ds-react-components";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { composeClasses } from "@kaltura/mediaspace-shared-styled";
import { TruncatedLine, TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import { getEventPresenterClass } from "../event-presenter/eventPresenterClasses";
import { EventPresenterLargeClasses } from "./eventPresenterLargeClasses";
import { EventPresenterProps } from "../event-presenter/EventPresenter";
import { EventPresenterWithModal } from "../event-presenter-with-modal/EventPresenterWithModal";
import { UserThumbnail } from "@kaltura/mediaspace-shared-user";

export interface EventPresenterLargeProps {
    className?: string;
    /** data to show */
    data: KmsTypePresenter;
    classes?: Partial<EventPresenterLargeClasses>;
    /**
     * show presenter modal on click
     */
    showPresenterModal?: boolean;
}

const Presenter = styled(EventPresenterWithModal)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    width: "100%",
}));

const Details = styled("div")({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
});

const Name = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone1,
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
}));
const ContainerDiv = styled("div")({
    lineHeight: 0,
});

const useUtilityClasses = ({ classes }: Partial<EventPresenterProps>) => {
    const slots = {
        root: ["root"],
        details: ["details"],
        name: ["name"],
        title: ["title"],
        thumb: ["thumb"],
    };

    return composeClasses(slots, getEventPresenterClass, classes);
};

export function EventPresenterLarge(props: EventPresenterLargeProps) {
    const { data, className, showPresenterModal = false } = props;
    const classes = useUtilityClasses(props);

    return (
        <Presenter className={clsx(classes.root, className)} presenter={data} showPresenterModal={showPresenterModal}>
            <Tooltip title={data.name} describeChild={true}>
                <ContainerDiv>
                    <UserThumbnail {...data} size={64} className="kms-ds-event-presenter-thumbnail"></UserThumbnail>
                </ContainerDiv>
            </Tooltip>

            <Details className={classes.details}>
                <Name className={classes.name} variant={"body2Highlight"}>
                    <TruncatedLine>{data.name}</TruncatedLine>
                </Name>
                <TruncateWithTooltip text={data.title} lines={2} tokenize={"words"}>
                    <Title className={classes.title} variant={"body2"}>
                        {data.title}
                    </Title>
                </TruncateWithTooltip>
            </Details>
        </Presenter>
    );
}

export default EventPresenterLarge;
