import { MouseEvent, useState } from "react";
import { Button, Menu, Tooltip } from "@kaltura/ds-react-components";
import { Calendar24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { AddToCalendarMenuItems } from "@kaltura/mediaspace-shared-entry";
import { ButtonTextWrapper } from "../styled-components";

export interface MailMenuProps {
    entryId: string;
    categoryId?: number;
}

export function MailMenu({ entryId, categoryId }: MailMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendButtonAnalytics("Add to calendar - Session card - Menu open", ButtonClickAnalyticsType.MENU);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="kms-ds-event-list-item-menu">
            <Tooltip title={translate("Add To Calendar")} placement={"bottom"}>
                <Button
                    id={`EventListItemCalendarToggleBtn-${entryId}`}
                    variant={"borderless"}
                    aria-haspopup={true}
                    aria-expanded={open}
                    aria-controls={open ? `EventListItemCalendarToggleMenu-${entryId}` : undefined}
                    onClick={handleButtonClick}
                    className={`kms-ds-event-list-item-button-calendar`}
                    size={"large"}
                    color={"secondary"}
                >
                    <Calendar24Icon />
                    <ButtonTextWrapper>{translate("Add To Calendar")}</ButtonTextWrapper>
                </Button>
            </Tooltip>
            <Menu
                id={`EventListItemCalendarToggleMenu-${entryId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                aria-labelledby={`EventListItemCalendarToggleBtn-${entryId}`}
            >
                <AddToCalendarMenuItems
                    entryId={entryId}
                    categoryId={categoryId}
                    analyticsValue={{ location: "Session card" }}
                    onItemClicked={handleMenuClose}
                />
            </Menu>
        </div>
    );
}

export default MailMenu;
