import { FunctionComponent } from "react";
import clsx from "clsx";
import styled from "@emotion/styled";
import { UserThumbnail } from "@kaltura/mediaspace-shared-user";
import { Typography } from "@kaltura/ds-react-components";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { TruncatedLine } from "@kaltura/mediaspace-shared-ui";
import { composeClasses, Box } from "@kaltura/mediaspace-shared-styled";
import { EventPresenterClasses, getEventPresenterClass } from "./eventPresenterClasses";
import { EventPresenterWithModal } from "../event-presenter-with-modal/EventPresenterWithModal";
import { Size } from "@kaltura/ds-react-components";

export interface EventPresenterProps {
    className?: string;
    data: KmsTypePresenter /** data to show */;
    inPopover?: boolean;
    isTranslucent?: boolean;
    // Show only the thumbnails, no details
    compact?: boolean;
    classes?: Partial<EventPresenterClasses>;
    /**
     * show presenter modal on click
     */
    showPresenterModal?: boolean;
    /**
     * thumbnail size
     */
    size?: Size;
}

const Presenter = styled(EventPresenterWithModal)({
    display: "flex",
});
const Details = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
}));

const Name = styled(
    Typography,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "isTranslucent";
        },
    }
)<{ isTranslucent?: boolean }>(({ theme, isTranslucent }) => ({
    color: isTranslucent ? theme.kaltura.palette.translucent.main : theme.kaltura.palette.tone1,
    fontSize: theme.typography.pxToRem(theme.kaltura.typography.fontSize),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    lineHeight: "normal",
    margin: 0,
    marginTop: 1,
}));

const Title = styled(
    Typography,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "isTranslucent" && propName !== "inPopover";
        },
    }
)<{ isTranslucent?: boolean; inPopover?: boolean }>(({ theme, isTranslucent, inPopover }) => ({
    color: isTranslucent ? theme.kaltura.palette.translucent.main : theme.kaltura.palette.tone2,
    fontSize: theme.typography.pxToRem(theme.kaltura.typography.fontSize),
    lineHeight: "normal",
    margin: 0,
    ...(
        inPopover && {
            color: theme.kaltura.palette.tone1,
        }
    ),
}));

const useUtilityClasses = ({ classes }: Partial<EventPresenterProps>) => {
    const slots = {
        root: ["root"],
        details: ["details"],
        name: ["name"],
        title: ["title"],
    };

    return composeClasses(slots, getEventPresenterClass, classes);
};

/**
 * Event Session Presenter, used in event list items
 */
export const EventPresenter: FunctionComponent<EventPresenterProps> = (props) => {
    const { data, className, isTranslucent, inPopover, compact, showPresenterModal = false, size = "medium" } = props;
    const classes = useUtilityClasses(props);

    const componentType = inPopover ? "kms-ds-inPopover" : isTranslucent ? "kms-ds-translucent" : "kms-ds-default";

    return (
        <Presenter
            className={clsx(classes.root, componentType, className)}
            presenter={data}
            showPresenterModal={showPresenterModal}
        >
            <Box>
                <UserThumbnail {...data} size={size} className="kms-ds-event-presenter-thumbnail"></UserThumbnail>
            </Box>

            {!compact && (
                <Details className={classes.details}>
                    <Name className={classes.name} isTranslucent={isTranslucent} component="div">
                        <TruncatedLine>{data.name}</TruncatedLine>
                    </Name>

                    <Title
                        className={classes.title}
                        paragraph={true}
                        isTranslucent={isTranslucent}
                        inPopover={inPopover}
                        component="div"
                    >
                        <TruncatedLine>{data.title}</TruncatedLine>
                    </Title>
                </Details>
            )}
        </Presenter>
    );
};

export default EventPresenter;
