import { Component, PropsWithChildren } from "react";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";

export interface ErrorBoundaryProps {
    fallback?: React.ReactNode;
}

interface State {
    hasError: boolean;
}

/**
 * Error boundary for components that fetch kms data
 */
export class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, State> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any) {
        console.log("ErrorBoundary", error, info);
    }

    render() {
        if (this.state.hasError) {
            if (this.props.fallback) {
                return this.props.fallback;
            }

            return <ErrorBoundaryFallback />;
        }

        return this.props.children;
    }
}
