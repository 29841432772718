import { TimeDisplay, WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import {
    formatEntryThumbnailUrl,
    translate,
    translateTime,
    useMediaQuery,
    WebcastHelper,
    WebcastStatus,
} from "@kaltura/mediaspace-shared-utils";
import { TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import React, { ReactNode, useState } from "react";
import styled from "@emotion/styled";
import { Tooltip, Typography } from "@kaltura/ds-react-components";
import { ActionIconButton } from "../event-list-item/action-section/StyledComponents";
import { Info24Icon } from "@kaltura/ds-react-icons";
import EventListItemModal from "../event-list-item-modal/EventListItemModal";
import { useTheme } from "@kaltura/mediaspace-shared-styled";

export interface SlimEventListItemProps {
    /**
     * The list entry data.
     */
    entry: WebcastEntry;
    /**
     * Optional call-to-action button to be displayed.
     */
    callToActionButton?: ReactNode;
    /**
     * Optional flag indicating if the entry is in the watchlist.
     * This indication will pe presented only in entry details modal.
     */
    inWatchlist?: boolean;
    /**
     * Optional function to update the agenda storage.
     */
    updateMyAgendaStorage?: (sessions: WebcastEntry[]) => void;
    /**
     * Optional function to handle toggling the watchlist status
     */
    onToggleWatchList?: (id: string, add: boolean) => void;
    /**
     * Optional callback invoked when more info clicked.
     */
    onShowInfo?: () => void;
}

const Thumbnail = styled("img")(({ theme }) => ({
    width: 190,
    height: 106,
    borderRadius: theme.kaltura.shape.roundness2,
}));

const ItemContainer = styled("div")(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(3),
    borderRadius: theme.kaltura.shape.roundness2,
    backgroundColor: theme.kaltura.palette.surfaces.paper,
    justifyContent: "space-between",
}));

const ItemInfoAndActions = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "128px",
    paddingRight: theme.spacing(3),
}));

const DateLabel = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
    paddingBottom: theme.spacing(1),
}));

const Actions = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
}));

/**
 * SlimEventListItem component
 *
 * This component displays a slim version of an event list item with a thumbnail.
 * It will render short entry details (name and date),
 * configurable action and more info button which opens a modal with event item full details.
 *
 */
export const SlimEventListItem = ({
    entry,
    callToActionButton,
    inWatchlist,
    updateMyAgendaStorage,
    onToggleWatchList,
    onShowInfo,
}: SlimEventListItemProps) => {
    const [showReadMoreModal, setShowReadMoreModal] = useState(false);
    const context = useConfig();
    const { timeDisplay, dateFormats } = context.application;
    const timeFormat = timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM
    const startTimeFormat = timeFormat;
    const endTimeFormat = `${timeFormat} z`;
    const formattedDate = translateTime(entry.schedulingData.start.timestamp, dateFormats.shortDate);
    const formattedStartTime = translateTime(entry.schedulingData.start.timestamp, startTimeFormat);
    const formattedEndTime = translateTime(entry.schedulingData.end.timestamp, endTimeFormat);

    const status = WebcastHelper.getLiveStatus(entry.schedulingData);

    const theme = useTheme();
    const showThumb = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.sm));

    const showItemInfo = () => {
        setShowReadMoreModal(true);
        onShowInfo?.();
    };

    return (
        <ItemContainer className={"kms-ds-slim-event-list-item"}>
            <ItemInfoAndActions>
                <div>
                    <DateLabel variant={"body2Highlight"} className={"chromatic-ignore"}>
                        {`${formattedDate} ${formattedStartTime}-${formattedEndTime}`}
                    </DateLabel>

                    <TruncateWithTooltip text={entry.name} lines={2}>
                        <Typography component={"h3"} variant={"h5"}>
                            {entry.name}
                        </Typography>
                    </TruncateWithTooltip>
                </div>

                <Actions className={"kms-ds-slim-event-list-item-actions"}>
                    {callToActionButton && callToActionButton}
                    <EventListItemModal
                        data={entry}
                        show={showReadMoreModal}
                        onHide={() => setShowReadMoreModal(false)}
                        startDate={entry.schedulingData.start.timestamp}
                        endDate={entry.schedulingData.end.timestamp}
                        startDateFormat={startTimeFormat}
                        endDateFormat={endTimeFormat}
                        thumbnailUrl={formatEntryThumbnailUrl(entry.thumbnailUrl, "auto", "auto")}
                        imageAltText={translate("%1 session image", [entry.name])}
                        isLive={status === WebcastStatus.live}
                        callToActionButton={callToActionButton}
                        updateMyAgendaStorage={updateMyAgendaStorage}
                        inWatchlist={inWatchlist}
                        onToggleWatchList={onToggleWatchList}
                    />
                    <Tooltip title={translate("More info - %1", [entry.name])} placement={"bottom-start"}>
                        <ActionIconButton
                            variant={"borderless"}
                            onClick={showItemInfo}
                            size={"large"}
                            aria-label={translate("More info - %1", [entry.name])}
                            color={"secondary"}
                        >
                            <Info24Icon />
                        </ActionIconButton>
                    </Tooltip>
                </Actions>
            </ItemInfoAndActions>
            {showThumb && <Thumbnail src={entry.thumbnailUrl} alt={""} />}
        </ItemContainer>
    );
};
