import { EditFormProps } from "./EditFormProps";
import { SaveEditFormResponse } from "./SaveEditFormResponse";
import { useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";
import { baseUrl, postKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { ConfirmationModalData } from "@kaltura/mediaspace-shared-ui";

/**
 * The logic of the EditForm component.
 *
 * Use this hook together with the EditFormContents component
 * to render an entry edit form with a customized actions panel.
 */
export const useEditForm = ({
    entryIds,
    formData,
    shouldClose = false,
    onSubmitStarted,
    onSubmit,
    onClose,
    closeAfterSubmit = true,
    onlyBasicData = false,
}: EditFormProps) => {
    const [modalData, setModalData] = useState<ConfirmationModalData>();
    const [processing, setProcessing] = useState<boolean>(false);
    const formId = useId();

    const useFormHookMethods = useForm({
        mode: "onChange",
        defaultValues: formData,
    });

    const canSaveForm =
        Object.keys(useFormHookMethods.formState.dirtyFields).length !== 0 && useFormHookMethods.formState.isValid;

    // reset all local changes
    const handleCancel = () => {
        useFormHookMethods.reset(formData);
        onClose();
    };

    const handleLeave = () => {
        setModalData(undefined);
        handleCancel();
    };

    const handleClose = () => {
        // useFormHookMethods.formState.isDirty compares current values to default values,
        // so it always returns true because we only pass default values for name, description, tags.
        // instead, look at the list of dirty fields.
        if (Object.keys(useFormHookMethods.formState.dirtyFields).length !== 0) {
            // populate the `are-you-sure` modal:
            setModalData({
                yesCallback: () => {
                    if (!useFormHookMethods.formState.isValid) {
                        setModalData(undefined);
                        return;
                    }
                    // only if form is valid
                    onClose();
                },
                yesButtonLabel: translate("Save Changes"),
                title: translate("Unsaved Changes"),
                text: translate("Changes aren't saved. Are you sure you want to leave without saving them?"),
            });
        }
        else {
            handleCancel();
        }
    };

    // save the values
    const handleSubmitForm = useFormHookMethods.handleSubmit(async (values) => {
        setProcessing(true);
        onSubmitStarted?.();

        let response: SaveEditFormResponse;
        try {
            response = await postKmsData(`${baseUrl}/default/media/update`, { entryIds, data: values, onlyBasicData });
        }
        catch (error: unknown) {
            response = { error: String(error), result: [] };
        }

        setProcessing(false);
        onSubmit?.(response);

        if (response.error) {
            console.error("Failed to edit entries", response.error);
        }
        else {
            if (closeAfterSubmit) {
                onClose();
            }
            else {
                useFormHookMethods.reset(values);
            }
        }
    });

    useEffect(() => {
        if (shouldClose) {
            handleClose();
        }
    }, [shouldClose]);

    return {
        useFormHookMethods,
        formId,
        modalData,
        processing,
        canSaveForm,
        handleSubmitForm,
        handleCancel,
        handleLeave,
    };
};

export type UseEditFormResponse = ReturnType<typeof useEditForm>;
