import { generateUtilityClass, generateUtilityClasses } from "@kaltura/mediaspace-shared-styled";

import { composeClasses } from "@kaltura/mediaspace-shared-styled";

export function getBaseFormLabelClass(slot: string) {
    return generateUtilityClass("kms-ds-form-label", slot);
}

export interface BaseFormLabelClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the label element */
    label: string;
    /**  styles applied to the label additional info element */
    labelInfo: string;
}

export type FormLabelClassKey = keyof BaseFormLabelClasses;

export const baseFormLabelClasses = generateUtilityClasses("kms-ds-form-label", ["root", "label", "labelInfo"]);

export default baseFormLabelClasses;

export const useUtilityClasses = () => {
    const slots = {
        root: ["root"],
        label: ["label"],
        labelInfo: ["labelInfo"],
    };

    return composeClasses(slots, getBaseFormLabelClass);
};
