import { FunctionComponent, KeyboardEvent, ReactNode, RefObject, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import {
    encodeUnicode,
    formatEntryThumbnailUrl,
    HtmlReactParser,
    safeSetTimeout,
    stripTags,
    translate,
    translateTime,
    useMediaQuery,
    WebcastHelper,
    WebcastStatus,
} from "@kaltura/mediaspace-shared-utils";
import { ButtonClickAnalyticsType, HeadingLevel, TimeDisplay, WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { useButtonAnalytics, useEntryUrl } from "@kaltura/mediaspace-shared-hooks";
import { EventListItemModal } from "../event-list-item-modal/EventListItemModal";
import { EventPresenterLargeList } from "../event-presenter/event-presenter-large-list/EventPresenterLargeList";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import {
    Container,
    Description,
    EntryLink,
    Name,
    SchedulingContainer,
    SchedulingLine,
    StyledActionSection,
    StyledContent,
    StyledEventListItem,
    Tags,
    Thumbnail,
} from "./styled-components";
import { getCallToAction } from "./getCallToAction";
import { StatusLive, TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import { RsvpService, RsvpStatus } from "@kaltura/mediaspace-shared-entry";

export interface EventListItemProps {
    className?: string;
    data: WebcastEntry /** entry to show */;
    categoryId?: number /** when entry is viewed in the context of a category */;
    vodCallback: (
        entryId: string,
        entryName: string,
        categoryId?: number
    ) => void /** "watch vod" action button callback */;
    liveCallback: (
        entryId: string,
        entryName: string,
        categoryId?: number
    ) => void /** "join live" action button callback */;
    entryLink?: string /** link to entry page, with or without category context */;
    updateMyAgendaStorage?: (sessions: WebcastEntry[]) => void;
    getInMyAgenda?: (session: WebcastEntry) => boolean;
    disabledMode?: boolean;
    forwardedRef?: RefObject<HTMLAnchorElement>;
    inWatchlist?: boolean;
    analyticsValue?: string;
    onToggleWatchList?: (id: string, add: boolean) => void;
    headingLevel?: HeadingLevel;
    /** to prevent presenter-modal -> event-list -> prersenter-modal ad infinitum */
    hidePresenterModal?: boolean;
}

/**
 * Event Session Item, mostly as part of a list of event sessions
 * Displays scheduled-entry data, including scheduling info.
 */
export const EventListItem: FunctionComponent<EventListItemProps> = ({
    className = "",
    data,
    vodCallback,
    liveCallback,
    entryLink,
    updateMyAgendaStorage, // update state in my agenda tab for disappearing entries
    inWatchlist,
    disabledMode,
    forwardedRef,
    analyticsValue,
    onToggleWatchList,
    categoryId,
    headingLevel,
    hidePresenterModal = false,
}) => {
    const context = useConfig();
    const { timeDisplay, dateFormats } = context.application;

    // context params
    const showThumb = context.eventPlatform?.eventListItem?.showThumbnail;
    const showPresenterModal = context.eventPlatform?.eventListItem?.showPresenterModal && !hidePresenterModal;
    const joinBuffer = context.eventPlatform?.eventListItem?.joinBuffer;

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

    //January 23, 2020 9:00 AM - 9:30 AM EST /12 hours || 9:00 - 09:30 EST /24 hours
    const timeFormat = timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM
    const startTimeFormat = timeFormat;
    const endTimeFormat = `${timeFormat} z`;
    const formattedStartTime = translateTime(data.schedulingData.start.timestamp, startTimeFormat);
    const formattedEndTime = translateTime(data.schedulingData.end.timestamp, endTimeFormat);

    const sendButtonAnalytics = useButtonAnalytics();

    const formattedTime = formattedStartTime + " - " + formattedEndTime;
    const formattedDate = translateTime(data.schedulingData.start.timestamp, dateFormats.shortDate);
    const status = WebcastHelper.getLiveStatus(data.schedulingData);
    const [callToActionButton, setCallToActionButton] = useState<ReactNode | null>(null);
    const [rsvpStatus, setRsvpStatus] = useState<RsvpStatus | undefined>();

    const onRsvpChange = useCallback(
        (status: RsvpStatus | undefined) => {
            setRsvpStatus(status);
        },
        []
    );

    const handleGetCallToAction = useCallback(
        (bufferStatus: WebcastStatus) => {
            setCallToActionButton(
                getCallToAction(
                    bufferStatus,
                    data.id,
                    data,
                    vodCallback,
                    liveCallback,
                    sendButtonAnalytics,
                    context,
                    disabledMode,
                    categoryId,
                    rsvpStatus,
                    onRsvpChange
                )
            );
        },
        [
            categoryId,
            context,
            data,
            disabledMode,
            liveCallback,
            onRsvpChange,
            sendButtonAnalytics,
            vodCallback,
            rsvpStatus,
        ]
    );

    // UseEffect to fetch initial RSVP status for future entries if cross-site RSVP enabled
    // runs only when component mounted
    useEffect(() => {
        const bufferSchedulingData = {
            start: { ...data.schedulingData.start },
            end: { ...data.schedulingData.end },
        };
        const bufferStatus = WebcastHelper.getLiveStatus(bufferSchedulingData);
        if (bufferStatus === WebcastStatus.future && context.rsvp?.isCrossSite) {
            RsvpService.getRsvpStatus(data.id).then((rsvpStatusResult) => {
                setRsvpStatus(rsvpStatusResult);
            });
        }
    }, []);

    /**
     * update CTA from 'Add to Calendar' or 'RSVP' to 'Join now' when the joinBuffer is reached.
     * see SessionCardJoinNowIndication config in Widgets module for more info.
     * default is 15 minutes before event start time
     */
    useEffect(() => {
        // decrease joinBuffer from start timestamp
        const bufferSchedulingData = {
            start: { ...data.schedulingData.start },
            end: { ...data.schedulingData.end },
        };
        if (joinBuffer) {
            bufferSchedulingData.start.timestamp = bufferSchedulingData.start.timestamp - joinBuffer * 60;
        }
        // get live status per join buffer
        const bufferStatus = WebcastHelper.getLiveStatus(bufferSchedulingData);
        // get call to action per buffer live status
        handleGetCallToAction(bufferStatus);

        // calculate seconds to joinBuffer
        const now = Date.now() / 1000;
        const secondsUntilBuffer = bufferSchedulingData.start.timestamp - now;

        // return if event is not in the future
        if (secondsUntilBuffer < 0) {
            return;
        }

        // schedule updating CTA from 'Add to Calendar' to 'Join now' when joinBuffer is reached
        const timeoutId = safeSetTimeout(
            () => {
                handleGetCallToAction(WebcastStatus.live);
            },
            secondsUntilBuffer * 1000
        );

        return () => clearTimeout(timeoutId);
    }, [data.schedulingData, handleGetCallToAction, joinBuffer]);

    const [showReadMoreModal, setShowReadMoreModal] = useState(false);

    // disable title click by tab in disabledMode
    const handleDisabledMode = (event: KeyboardEvent) => {
        if (disabledMode && event.key !== "Tab") {
            event.preventDefault();
        }
    };

    entryLink = useEntryUrl({ entry: data, entryUrl: entryLink });

    const handleShowMore = (action: string) => {
        setShowReadMoreModal(true);
        sendButtonAnalytics(action, ButtonClickAnalyticsType.LAUNCH);
    };

    return (
        <StyledEventListItem
            className={clsx("kms-ds-event-list-item", className)}
            data-analytics={encodeUnicode(data.name)}
        >
            <StyledContent>
                <SchedulingContainer className={"kms-ds-event-list-item-date chromatic-ignore"}>
                    <SchedulingLine
                        variant={"body2Highlight"}
                        content={"time"}
                        className={"kms-ds-event-list-item-date-time"}
                    >
                        {formattedTime}
                    </SchedulingLine>
                    <SchedulingLine
                        variant={"body2Highlight"}
                        content={"date"}
                        className={"kms-ds-event-list-item-date-date"}
                    >
                        {formattedDate}
                    </SchedulingLine>
                    {status === WebcastStatus.live && (
                        <StatusLive className={"kms-ds-event-list-item-status"}>{translate("Live")}</StatusLive>
                    )}
                </SchedulingContainer>

                <Container className="kms-ds-event-list-item-content">
                    <TruncateWithTooltip text={data.name} tooltipPlacement={"top"}>
                        <Name component={headingLevel ?? "h3"} variant={"h5"}>
                            <EntryLink
                                className={"kms-ds-event-list-item-title"}
                                href={entryLink}
                                disabled={disabledMode}
                                ref={forwardedRef}
                                onKeyDown={handleDisabledMode}
                                onClick={() =>
                                    sendButtonAnalytics("Session card - Name", ButtonClickAnalyticsType.LINK)
                                }
                            >
                                {data.name}
                            </EntryLink>
                        </Name>
                    </TruncateWithTooltip>
                    <Tags className="kms-ds-event-list-item-tags" tags={data.tags} />
                    <Description
                        className="kms-ds-event-list-item-description"
                        lines={2}
                        showMore={false}
                        preventStateUpdate={true}
                    >
                        {/* we use HtmlReactParser here to decode html entities */}
                        <>{HtmlReactParser(stripTags(data.description ?? ""))}</>
                    </Description>

                    {data.presenters && (
                        <EventPresenterLargeList
                            presenters={data.presenters}
                            className="kms-ds-event-list-item-presenters"
                            showPresenterModal={showPresenterModal}
                            onShowMore={() => handleShowMore("Session card - Speakers more button")}
                        />
                    )}

                    <EventListItemModal
                        data={data}
                        show={showReadMoreModal}
                        onHide={() => setShowReadMoreModal(false)}
                        startDate={data.schedulingData.start.timestamp}
                        endDate={data.schedulingData.end.timestamp}
                        startDateFormat={startTimeFormat}
                        endDateFormat={endTimeFormat}
                        thumbnailUrl={formatEntryThumbnailUrl(data.thumbnailUrl, "auto", "auto")}
                        imageAltText={translate("%1 session image", [data.name])}
                        isLive={status === WebcastStatus.live}
                        callToActionButton={callToActionButton}
                        updateMyAgendaStorage={updateMyAgendaStorage}
                        inWatchlist={inWatchlist}
                        onToggleWatchList={onToggleWatchList}
                    />
                </Container>

                {showThumb && isLargeScreen && (
                    <a
                        tabIndex={-1}
                        href={entryLink}
                        style={disabledMode ? { pointerEvents: "none" } : {}}
                        onKeyDown={handleDisabledMode}
                        className="kms-ds-event-list-item-image-wrapper"
                        onClick={() =>
                            sendButtonAnalytics(
                                analyticsValue ?? "Session card - Thumbnail",
                                ButtonClickAnalyticsType.THUMBNAIL
                            )
                        }
                    >
                        <Thumbnail src={data.thumbnailUrl} alt={data.name} className="kms-ds-event-list-item-image" />
                    </a>
                )}
            </StyledContent>

            <StyledActionSection
                className="kms-ds-event-list-item-actions"
                data={data}
                callToActionButton={callToActionButton}
                onShowMore={() => handleShowMore("Session card - more info button")}
                inWatchlist={inWatchlist}
                onToggleWatchList={onToggleWatchList}
            />
        </StyledEventListItem>
    );
};

export default EventListItem;
