import { forwardRef, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import { DatePicker } from "@kaltura/ds-react-components";
import dayjs, { Dayjs } from "dayjs";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface DateFieldProps {
    id: string;
    label: string;
    description?: string;
    /**
     * short-date representation of selected date, according to given dateFormat
     */
    value?: string;
    dateFormat?: string;
    additionalInfo?: string;
    fontWeightBold?: boolean;
}

/**
 * date field for edit entry form
 */
export const DateField = forwardRef<any, DateFieldProps>(
    ({ id, label, description, value, dateFormat = "DD/MM/YYYY", additionalInfo, fontWeightBold }, ref) => {
        const { control } = useFormContext();
        const inputProps = {
            id: id,
            classes: { root: "chromatic-ignore" },
        };

        const convertValueToDayjs = useCallback(
            (value: string) => {
                // on server we save unix timestamp, but we need to send short-date-format
                // DatePicker component takes dayjs format
                return dayjs(value, dateFormat);
            },
            [dateFormat]
        );

        const handleChange = (formOnChange: (...event: any[]) => void) => {
            return (dayjsValue: Dayjs | null) => {
                if (!dayjsValue) {
                    return;
                }
                // put updated value on form model
                const newval = dayjsValue.format(dateFormat);
                formOnChange(newval);
            };
        };

        return (
            <Container>
                <StyledFormLabel
                    htmlFor={id}
                    text={label}
                    description={description}
                    additionalInfo={additionalInfo}
                    fontWeightBold={fontWeightBold}
                />
                <Controller
                    control={control}
                    name={id as "dummy"}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DatePicker
                            format={dateFormat}
                            inputProps={{ ...inputProps, onBlur: onBlur }}
                            slotProps={{
                                openPickerButton: {
                                    "aria-label": translate("%1, choose date", [label || ""]),
                                },
                            }}
                            value={value ? convertValueToDayjs(value) : null}
                            inputRef={ref}
                            onChange={handleChange(onChange)}
                        />
                    )}
                />
            </Container>
        );
    }
);
