import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Container, StyledFormLabel, StyledFormHelperText } from "./CommonStyles";
import { Switch } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";
// noinspection ES6PreferShortImport
import { FormLabel } from "../form/form-label/FormLabel";

export interface SwitchFieldProps {
    id: string;
    label: string;
    description?: string;
    value?: boolean;
    required?: boolean;
    disabled?: boolean;
    fontWeightBold?: boolean;
}

const InlineContainer = styled(Container)(({ theme }) => ({
    marginTop: 0,
    flexDirection: "row",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        display: "flex", // override original
    },
}));

/**
 * switch field
 */
export const SwitchField = forwardRef<any, SwitchFieldProps>(
    ({ id, label, description, value, fontWeightBold, required = false, disabled = false }, ref) => {
        const { control } = useFormContext();

        return (
            <Container>
                <StyledFormLabel htmlFor={id} text={label} required={required} fontWeightBold={fontWeightBold} />
                <StyledFormHelperText id={`${id}-description`}>{description}</StyledFormHelperText>
                <InlineContainer>
                    <Controller
                        control={control}
                        name={id as "dummy"}
                        defaultValue={value}
                        rules={{ required: required }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Switch
                                id={id}
                                inputRef={ref}
                                onChange={onChange}
                                onBlur={onBlur}
                                checked={value}
                                disabled={disabled}
                                inputProps={{ "aria-describedby": `${id}-description` }}
                            />
                        )}
                    />
                    <FormLabel text={value ? translate("On") : translate("Off")} required={required} />
                </InlineContainer>
            </Container>
        );
    }
);
