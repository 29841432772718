import styled from "@emotion/styled";
import sortBy from "lodash/sortBy";
import { useEffect, useMemo, useState } from "react";
import { Link } from "@kaltura/ds-react-components";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { getEntryUrl, translate } from "@kaltura/mediaspace-shared-utils";
import { EventListItem } from "../event-list-item/EventListItem";
import type { EventListProps, ScheduledSession } from "./EventList";
import dayjs from "dayjs";

const StyledFilterButton = styled(Link)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledEventListItem = styled(EventListItem)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const sortSessionsByStartTime = (sessions: ScheduledSession[]) =>
    sortBy(sessions, (session) => session.schedulingData.start.timestamp);

/**
 * Channel Event List
 *
 * Shows all sessions / scheduled sessions
 * Displays scheduled-entry data, including scheduling info
 */
export function ChannelEventList(props: EventListProps) {
    const { sessions = [], disabledMode = false, hideFilterButton, categoryId, onToggleWatchList } = props;
    const [showScheduled, setShowScheduled] = useState<boolean>(false);
    const [sessionsToShow, setSessionsToShow] = useState<ScheduledSession[]>([]);
    const [sortedSessions, setSortedSessions] = useState<ScheduledSession[]>([]);
    const [sessionsInScheduleRange, setSessionsInScheduleRange] = useState<boolean>(false);

    const config = useConfig();
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const goToEntryPage = (entryId: string, entryName?: string, categoryId?: number) => {
        entryName = enableEntryTitles ? entryName : undefined;
        document.location.href = getEntryUrl(entryId, categoryId, entryName);
    };

    const handleFilterButtonClick = () => {
        setShowScheduled((prev) => !prev);
    };

    useMemo(() => {
        if (sessions.length !== 0) {
            const earliestSession = sessions.reduce(function (prev, curr) {
                return prev.schedulingData.start.timestamp < curr.schedulingData.start.timestamp ? prev : curr;
            });
            const latestSession = sessions.reduce(function (prev, curr) {
                return prev.schedulingData.end.timestamp > curr.schedulingData.end.timestamp ? prev : curr;
            });
            // check if the earliest session is in the future or if the latest session has ended to determine if to display view scheduled only button or not
            const dayJsTimeNow = dayjs(new Date().toISOString()).unix();
            const futureWebcast = earliestSession.schedulingData.start.timestamp > dayJsTimeNow;
            const pastWebcast = latestSession.schedulingData.end.timestamp < dayJsTimeNow;
            setSessionsInScheduleRange(pastWebcast || futureWebcast);
        }
    }, [sessions]);

    useEffect(() => {
        setSortedSessions(sortSessionsByStartTime(sessions));
    }, [sessions]);

    useEffect(() => {
        const showScheduledSessions = () =>
            sortedSessions.filter(
                (session) => !!session.schedulingData && session.schedulingData.end.timestamp > Date.now() / 1000
            );

        setSessionsToShow(showScheduled ? showScheduledSessions() : sortedSessions);
    }, [showScheduled, sortedSessions]);

    return (
        <div>
            {!hideFilterButton && !sessionsInScheduleRange && (
                <StyledFilterButton component="button" size={"small"} onClick={handleFilterButtonClick}>
                    {showScheduled ? translate("View All Sessions") : translate("View Scheduled Only")}
                </StyledFilterButton>
            )}
            {sessionsToShow.map((entry) => (
                <StyledEventListItem
                    data={entry}
                    key={`channel-session_${entry.id}`}
                    categoryId={categoryId}
                    vodCallback={goToEntryPage}
                    liveCallback={goToEntryPage}
                    entryLink={getEntryUrl(entry.id, categoryId, enableEntryTitles ? entry.name : undefined)}
                    inWatchlist={entry.inWatchlist}
                    onToggleWatchList={onToggleWatchList}
                    disabledMode={disabledMode}
                    analyticsValue={"Sessions - Media Thumbnail"}
                />
            ))}
        </div>
    );
}

export default ChannelEventList;
