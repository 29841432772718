import { useEffect, useState } from "react";
import { ModalActions, ModalTitle, ModalContent, Button, Typography, Modal, Link } from "@kaltura/ds-react-components";
import { baseUrl, getEntryUrl, postKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { useToastsContext } from "@kaltura/mediaspace-shared-contexts";
import { SharedMenuItemProps } from "@kaltura/mediaspace-shared-types";
import { useEventHandler, useLastValueRef } from "@kaltura/mediaspace-shared-hooks";
import { EditFormContents } from "../../../../edit-form/EditFormContents";
import { EditFormElement } from "../../../../edit-form/EditFormElement";
import { useEditForm } from "../../../../edit-form/useEditForm";
import { EntryMetadataStructure } from "@kaltura/mediaspace-shared-upload";

export interface CloneKmeRoomProps extends SharedMenuItemProps {
    entryId: string;
    formData: EntryMetadataStructure;
    fieldsStructure: EditFormElement[];
    reloadMedia?: () => void;
}

export interface CloneKmeRoomModalProps extends CloneKmeRoomProps {
    isOpen: boolean;
}

/**
 * A modal window that initiates cloning a KME room and allows to edit the cloned room's metadata.
 *
 * The component is designed to be rendered from a media action menu item.
 */
export const CloneKmeRoomModal = (props: CloneKmeRoomModalProps) => {
    const { isOpen, entryId, formData, fieldsStructure, onCloseMenu, reloadMedia } = props;
    const [newEntryId, setNewEntryId] = useState("");
    const [cloneProgress, setCloneProgress] = useState(0);

    const { showToast } = useToastsContext();

    const formHookProps = useEditForm({
        entryIds: [newEntryId],
        formData,
        fieldsStructure,
        onSubmit: (response) => {
            if (response.error) {
                showToast({ severity: "error", message: translate("Failed to update information") });
            }
            else {
                showToast({
                    severity: "success",
                    message: translate("Your information has been successfully updated."),
                });
            }
        },
        onClose: () => onCloseMenu?.(),
        closeAfterSubmit: false,
        onlyBasicData: true,
    });

    // Whether the form actions should be disabled because of processing an API call
    const processingRequest = !newEntryId || formHookProps.processing;
    // Was the async job of copying room assets fully finished
    const isFinished = cloneProgress === 100;

    /*
     * Keep the reference to the up-to-date version of onCloseMenu callback.
     * The reference is being reset to undefined after closing the modal
     * to prevent taking unwanted actions after finishing async job (see onModalClose).
     */
    const onCloseMenuRef = useLastValueRef(onCloseMenu);

    const handleError = () => {
        showToast({ severity: "error", message: translate("Failed to clone the room") });
        onCloseMenuRef.current?.();
    };

    // Clone the room when opening the modal (when the user clicks the action item)
    const onModalOpen = useEventHandler(async () => {
        try {
            // Clone the room entry and start the async job of copying the assets
            const { success, entryId: newEntryId } = await postKmsData(
                `${baseUrl}/meetingentry/entry/clone`,
                { entryId }
            );
            if (!success) {
                handleError();
                return;
            }

            setNewEntryId(newEntryId);

            // Poll the status of the async jobs each 3 seconds until it finishes (successfully or with an error)
            // eslint-disable-next-line no-constant-condition
            while (true) {
                // Wait 3 seconds
                await new Promise<void>((resolve) => setTimeout(resolve, 3000));

                const { success, progress } = await postKmsData(
                    `${baseUrl}/meetingentry/entry/clone-progress`,
                    { entryId: newEntryId }
                );
                if (!success) {
                    handleError();
                    return;
                }

                setCloneProgress(progress);
                if (progress === 100) {
                    showToast({
                        severity: "success",
                        message: (
                            <>
                                {translate("Room was successfully cloned.")}{" "}
                                <Link href={getEntryUrl(newEntryId)}>{translate("Go to the new room")}</Link>
                            </>
                        ),
                    });
                    return;
                }
            }
        }
        catch (e: unknown) {
            console.error(e);
            handleError();
        }
    });

    const onModalClose = useEventHandler(() => {
        // Don't call onCloseMenu after the component was disposed
        onCloseMenuRef.current = undefined;

        // Reload the media list after disposing the component
        reloadMedia?.();
    });

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        onModalOpen();

        return () => onModalClose();
    }, [isOpen, onModalOpen, onModalClose]);

    return (
        <Modal open={isOpen} fullWidth={true} onClose={formHookProps.handleCancel}>
            <ModalTitle>
                <Typography variant={"h3"} component={"div"}>
                    {isFinished ? translate("Clone room is ready") : translate("Clone room in progress")}
                </Typography>
            </ModalTitle>
            <ModalContent>
                <Typography variant={"body1"} align={"center"} color={(theme) => theme.kaltura.palette.tone2}>
                    {isFinished
                        ? translate(
                              "Room was successfully cloned. You can continue editing the new room’s metadata or navigate to the new room."
                          )
                        : translate(
                              "We are in the process of copying all your room assets. This may take some time. You can edit the new room’s metadata in the meantime."
                          )}
                </Typography>

                <form
                    id={formHookProps.formId}
                    onSubmit={formHookProps.handleSubmitForm}
                    // Fix MUI bug for menu items
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <EditFormContents formData={formData} fieldsStructure={fieldsStructure} {...formHookProps} />
                </form>
            </ModalContent>
            <ModalActions>
                <Button
                    variant={"borderless"}
                    color={"secondary"}
                    disabled={processingRequest}
                    onClick={formHookProps.handleCancel}
                >
                    {translate("Close")}
                </Button>
                {isFinished && (
                    <Button variant={"borderless"} color={"secondary"} href={getEntryUrl(newEntryId)}>
                        {translate("Go to the new room")}
                    </Button>
                )}
                <Button
                    type={"submit"}
                    form={formHookProps.formId}
                    loading={processingRequest}
                    disabled={!formHookProps.canSaveForm}
                >
                    {translate("Save")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
