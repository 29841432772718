import { Button, MenuItemProps, Size, SplitButton, splitButtonClasses } from "@kaltura/ds-react-components";
import { Calendar24Icon, Mail24Icon, Rsvp24Icon } from "@kaltura/ds-react-icons";
import { baseUrl, postKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { Children, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useToastsContext } from "@kaltura/mediaspace-shared-contexts";
import { AddToCalendarMenuItems, StyledMenuItem } from "../add-to-calendar-items/AddToCalendarMenuItems";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { RsvpService } from "./RsvpService";
import styled from "@emotion/styled";

export enum RsvpStatus {
    UNCONFIRMED,
    CONFIRMED,
}

export interface RsvpButtonProps {
    entry: { id: string; name: string };
    ks?: string;
    initialStatus?: RsvpStatus;
    size?: Size;
    fetchStatusOnMount?: boolean;
    allowResendPostRsvp?: boolean;
    analyticsValue?: { value?: string; location?: string };
    onChange?: (status: RsvpStatus) => void;
    isLoading?: boolean;
    onToggleClicked?: () => void;
}

const StyledSplitButton = styled(
    SplitButton,
    {
        shouldForwardProp: (prop) => prop !== "status",
    }
)<{ status: RsvpStatus }>(({ status, size }) => ({
    [`.${splitButtonClasses.actionButton}`]: {
        width: status === RsvpStatus.UNCONFIRMED ? "105px" : size === "large" ? "180px" : "152px",
    },
}));

const StyledButton = styled(
    Button,
    {
        shouldForwardProp: (prop) => prop !== "status",
    }
)<{ status: RsvpStatus }>(({ status, size }) => ({
    width: status === RsvpStatus.UNCONFIRMED ? "105px" : size === "large" ? "180px" : "152px",
}));

export const RsvpButton = ({
    entry,
    ks,
    size,
    initialStatus = RsvpStatus.UNCONFIRMED,
    fetchStatusOnMount = false,
    allowResendPostRsvp,
    onChange,
    analyticsValue,
    isLoading,
    onToggleClicked,
}: RsvpButtonProps) => {
    const [currentStatus, setCurrentStatus] = useState(initialStatus);
    const [processing, setProcessing] = useState<boolean>();

    const config = useConfig();
    const { showToast } = useToastsContext();

    const textIconMappingByStatus = useMemo(
        () => ({
            [RsvpStatus.UNCONFIRMED]: { label: translate("RSVP"), icon: <Calendar24Icon /> },
            [RsvpStatus.CONFIRMED]: { label: translate("RSVP accepted"), icon: <Rsvp24Icon /> },
        }),
        []
    );

    const [buttonLabel, setButtonLabel] = useState(textIconMappingByStatus[currentStatus].label);

    useEffect(() => {
        setButtonLabel(textIconMappingByStatus[currentStatus].label);
    }, [currentStatus, textIconMappingByStatus]);

    const getSessionsRsvpStatus = useCallback(async () => {
        setProcessing(true);
        const rsvpStatusResult = await RsvpService.getRsvpStatus(entry.id);
        setCurrentStatus(rsvpStatusResult);
        setProcessing(false);
    }, [entry.id]);

    useEffect(() => {
        if (fetchStatusOnMount && config.rsvp?.isCrossSite) {
            config.application.isLoggedIn ? getSessionsRsvpStatus() : setCurrentStatus(RsvpStatus.UNCONFIRMED);
        }
    }, [
        fetchStatusOnMount,
        entry.id,
        ks,
        onChange,
        config.application.isLoggedIn,
        getSessionsRsvpStatus,
        config.rsvp?.isCrossSite,
    ]);

    useEffect(() => {
        setProcessing(isLoading || fetchStatusOnMount);
    }, [fetchStatusOnMount, isLoading]);

    useEffect(() => {
        setCurrentStatus(initialStatus);
    }, [initialStatus]);

    const handleRsvpStatusChange = async (newStatus: RsvpStatus) => {
        const action = newStatus === RsvpStatus.CONFIRMED ? "accept-rsvp" : "delete-rsvp";
        setProcessing(true);
        const errorMessage = translate("Something went wrong. We could not update your response.");
        try {
            const rsvpResult = await postKmsData(`${baseUrl}/rsvp/index/${action}`, { ks, entryId: entry.id });
            setProcessing(false);

            // if (rsvpResult.success) {
            setCurrentStatus(newStatus);
            const successMessage =
                newStatus === RsvpStatus.CONFIRMED
                    ? translate("Your response to the session '%1' has been recorded.", [entry.name])
                    : translate("Sorry you can't make it! We've noted your cancellation.");

            // Show toast only when not redirecting to login page
            if (!rsvpResult.redirect) {
                showToast({
                    severity: rsvpResult.success ? "success" : "error",
                    message: rsvpResult.success ? successMessage : errorMessage,
                });
                onChange?.(newStatus);
            }

            // Redirect to login page if not logged in
            if (!rsvpResult.success && rsvpResult.redirect) {
                window.location.href = `${rsvpResult.redirect}?ref=${baseUrl}${encodeURIComponent(window.location.pathname + window.location.search)}`;
                return;
            }
        }
        catch (e) {
            setProcessing(false);
            showToast({ severity: "error", message: errorMessage });
        }
    };

    const toggleRsvpStatus = () => {
        onToggleClicked?.();
        const newStatus = currentStatus === RsvpStatus.UNCONFIRMED ? RsvpStatus.CONFIRMED : RsvpStatus.UNCONFIRMED;
        handleRsvpStatusChange(newStatus);
        const analyticsName = RsvpStatus.UNCONFIRMED
            ? `RSVP to session${analyticsValue?.location ? ` - ${analyticsValue.location}` : ""}`
            : `Remove RSVP to session${analyticsValue?.location ? ` - ${analyticsValue.location}` : ""}`;
        sendAnalytics(ButtonClickAnalyticsType.REGISTER, analyticsName);
    };

    const rsvpHoverHandler = () => {
        if (currentStatus === RsvpStatus.CONFIRMED) {
            setButtonLabel(translate("Cancel RSVP"));
        }
    };

    const rsvpMouseLeaveHandler = () => {
        setButtonLabel(textIconMappingByStatus[currentStatus].label);
    };

    const splitButtonMenuItems = useMemo(() => {
        const resendPostRsvp = async () => {
            sendAnalytics(ButtonClickAnalyticsType.SEND, `${analyticsValue?.location} iCal - resend email`);

            try {
                const result = await postKmsData(`${baseUrl}/rsvp/index/resend-rsvp-ical`, { entryId: entry.id });
                if (result.success) {
                    showToast({ severity: "success", message: result.message });
                }
                else {
                    showToast({ severity: "error", message: result.error });
                }
            }
            catch (e) {
                showToast({ severity: "error", message: translate("Error sending calendar email") });
            }
        };

        if (config?.rsvp?.allowDownload) {
            return Children.toArray(
                <AddToCalendarMenuItems
                    entryId={entry.id}
                    ks={ks}
                    analyticsValue={{ location: analyticsValue?.location ?? "", module: "RSVP" }}
                />
            ) as ReactElement<MenuItemProps>[];
        }

        if (config?.rsvp?.isPostRsvpEmailEnabled && allowResendPostRsvp && currentStatus === RsvpStatus.CONFIRMED) {
            return [
                <StyledMenuItem component="a" onClick={resendPostRsvp}>
                    <Mail24Icon /> {translate("Resend iCal Invitation")}
                </StyledMenuItem>,
            ] as ReactElement<MenuItemProps>[];
        }

        return null;
    }, [
        allowResendPostRsvp,
        config?.rsvp?.allowDownload,
        config?.rsvp?.isPostRsvpEmailEnabled,
        currentStatus,
        entry.id,
        showToast,
    ]);

    const sendButtonAnalytics = useButtonAnalytics();
    const sendAnalytics = (type: ButtonClickAnalyticsType, name: string) => {
        sendButtonAnalytics(name, type, entry.id, "RSVP");
    };

    return splitButtonMenuItems ? (
        <StyledSplitButton
            loading={processing}
            status={currentStatus}
            buttonGroupAreaLabel={`${textIconMappingByStatus[currentStatus].label} - ${entry.name}`}
            size={size}
            buttonText={buttonLabel}
            startIcon={textIconMappingByStatus[currentStatus].icon}
            onClick={toggleRsvpStatus}
            menuItems={splitButtonMenuItems}
            onMouseEnter={rsvpHoverHandler}
            onFocus={rsvpHoverHandler}
            onMouseLeave={rsvpMouseLeaveHandler}
            onBlur={rsvpMouseLeaveHandler}
        />
    ) : (
        <StyledButton
            loading={processing}
            status={currentStatus}
            aria-label={`${textIconMappingByStatus[currentStatus].label} - ${entry.name}`}
            size={size}
            startIcon={textIconMappingByStatus[currentStatus].icon}
            onClick={toggleRsvpStatus}
            onMouseEnter={rsvpHoverHandler}
            onFocus={rsvpHoverHandler}
            onMouseLeave={rsvpMouseLeaveHandler}
            onBlur={rsvpMouseLeaveHandler}
        >
            {buttonLabel}
        </StyledButton>
    );
};
