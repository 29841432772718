import { baseUrl, postKmsData } from "@kaltura/mediaspace-shared-utils";
import { RsvpStatus } from "./RsvpButton";

/**
 * RsvpService class provides shared functions for to handle RSVP functionality.
 */
export class RsvpService {
    // get RsvpStatus for given entryId
    static async getRsvpStatus(entryId: string): Promise<RsvpStatus> {
        const rsvpStatusResult = await postKmsData(
            `${baseUrl}/rsvp/index/get-status`,
            {
                entryId,
            }
        );
        return rsvpStatusResult.success ? RsvpStatus.CONFIRMED : RsvpStatus.UNCONFIRMED;
    }
}
