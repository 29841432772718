import { baseUrl, download, getEntryUrl, getKmsData } from "@kaltura/mediaspace-shared-utils";
import UnisphereWidget, { UnisphereWidgetHandle, useUnispherePubSub } from "@kaltura/mediaspace-shared-unisphere";
import { useIsMobileSidebarLayout } from "@kaltura/ds-react-layouts";
import { useCallback, useMemo, useRef } from "react";
import { UnisphereMessage } from "@unisphere/core-js/src/lib/types/shared";

const CONTENT_LAB_PUB_SUB_EVENTS_NAME = "unisphere.event.module.content-lab.message-host-app";

// Enum to define the types of ContentLab widgets
export enum ContentLabWidgetType {
    CLIPS = "clips", // when selected create clips feature will be automatically selected
    QUIZ = "quiz", // when selected create quiz feature will be automatically selected
    SUMMARY = "summary", // when selected create summary feature will be automatically selected
}

export interface ContentLabCtaProps {
    // the partner id
    partnerId: string;
    // the entry id for which clips will be generated
    // for VOD entries will be initialized with the entry id
    // for live entries will be initialized with the recordingID
    entryId: string;
    // the entry context id - for all entry types (live or vod) it will be initialized with entry id
    sessionContextId: string;
    // uiConf id for the preview player inside the content lab
    playerId: string;
    // kaltura server url
    serverUrl: string;
    // uses user ks
    ks: string;
    appName: number;
    appVersion: string;
    buttonLabel?: string;
    // analytics server uri
    analyticsServerURI: string;
    // the type of the content lab widgets, if omitted the default value is CLIPS for backward compatibility
    widgets?: ContentLabWidgetType[];
}

const enum PostSaveActions {
    EDIT_CLIP = "edit",
    DOWNLOAD_CLIP = "download",
    NAVIGATE_TO_MEDIA = "media",
    EDIT_QUIZ = "editQuiz",
    DOWNLOAD_QUIZ = "downloadQuiz",
    EDIT_PLAYLIST = "editPlaylist",
    NAVIGATE_TO_PLAYLIST = "playlist",
}

const postSaveActionsByWidget = {
    [ContentLabWidgetType.CLIPS]: [
        PostSaveActions.EDIT_CLIP,
        PostSaveActions.DOWNLOAD_CLIP,
        PostSaveActions.NAVIGATE_TO_MEDIA,
        PostSaveActions.NAVIGATE_TO_PLAYLIST,
        PostSaveActions.EDIT_PLAYLIST,
    ],
    [ContentLabWidgetType.QUIZ]: [
        PostSaveActions.EDIT_QUIZ,
        PostSaveActions.DOWNLOAD_QUIZ,
        PostSaveActions.NAVIGATE_TO_MEDIA,
    ],
    [ContentLabWidgetType.SUMMARY]: [],
};

// if the widget is not specified, the default post save actions will be the union of the post save actions of the clips and quiz widgets
const allPostSaveActions = Array.from(new Set(Object.values(postSaveActionsByWidget).flat()));

/**
 * ContentLabCta component renders a ContentLab widget and handles its events.
 * It subscribes to Unisphere PubSub events and performs actions based on the event data.
 */
export const ContentLabCta = ({
    ks,
    partnerId,
    playerId,
    appName,
    appVersion,
    serverUrl,
    entryId,
    sessionContextId,
    analyticsServerURI,
    buttonLabel = "",
    widgets = [ContentLabWidgetType.CLIPS],
}: ContentLabCtaProps) => {
    // Reference to the UnisphereWidget instance
    const contentLabWidgetRef = useRef<UnisphereWidgetHandle>(null);
    const isMobile = useIsMobileSidebarLayout();
    const postSaveActions =
        !widgets || !widgets.length
            ? allPostSaveActions
            : Array.from(new Set(widgets?.flatMap((widget) => postSaveActionsByWidget[widget])));
    // Memoized settings for the contentLab widget
    const contentLabSettings = useMemo(
        () => ({
            ks,
            pid: partnerId,
            uiconfId: playerId,
            analyticsServerURI,
            hostAppName: appName,
            hostAppVersion: appVersion,
            kalturaServerURI: serverUrl,
            kalturaServerProxyURI: `${baseUrl}/contentlab/api`,
            clipsOverride: "",
            entryId,
            buttonLabel,
            eventSessionContextId: sessionContextId,
            widget: widgets?.join(","),
            postSaveActions: postSaveActions.join(","),
        }),
        [
            ks,
            partnerId,
            playerId,
            analyticsServerURI,
            appName,
            appVersion,
            serverUrl,
            entryId,
            buttonLabel,
            sessionContextId,
            widgets,
            postSaveActions,
        ]
    );

    // Closes the contentLab widget
    const closeContentLabWidget = useCallback(() => {
        if (contentLabWidgetRef.current) {
            const contentLabWidget = contentLabWidgetRef.current.getWidget();
            contentLabWidget.closeWidget();
        }
    }, []);

    const downloadQuizQuestions = useCallback(
        async (entry: any) => {
            const response = await getKmsData(
                `${baseUrl}/kalturaai/quiz/get-questions?entryId=${encodeURIComponent(entry.id)}`
            );
            if (response.success) {
                download(response.questionsDownloadUrl, entry.name);
            }
        },
        []
    );

    // Handles events from the contentLab widget
    const handleContentLabEvents = useCallback(
        (data: UnisphereMessage) => {
            const { action, entry } = data.payload;
            switch (action) {
                case PostSaveActions.NAVIGATE_TO_MEDIA:
                    closeContentLabWidget();
                    document.location.href = getEntryUrl(entry.id, undefined, entry.name);
                    break;
                case PostSaveActions.DOWNLOAD_CLIP:
                    download(`${entry.downloadUrl}?ks=${ks}`, entry.name);
                    break;
                case PostSaveActions.EDIT_CLIP:
                    closeContentLabWidget();
                    document.location.href = `${baseUrl}/editor/index/launch-editor?entryId=${entry.id}#kea-anchor`;
                    break;
                case PostSaveActions.EDIT_QUIZ:
                    closeContentLabWidget();
                    document.location.href = `${baseUrl}/editor/index/launch-editor?entryId=${entry.id}&tab=quiz`;
                    break;
                case PostSaveActions.DOWNLOAD_QUIZ:
                    downloadQuizQuestions(entry);
                    break;
                case PostSaveActions.EDIT_PLAYLIST:
                    document.location.href = `${baseUrl}/playlist/edit/${entry.id}`;
                    break;
                case PostSaveActions.NAVIGATE_TO_PLAYLIST:
                    document.location.href = `${baseUrl}/playlist/dedicated/${entry.id}`;
                    break;
                default:
                    break;
            }
        },
        [closeContentLabWidget]
    );

    useUnispherePubSub(CONTENT_LAB_PUB_SUB_EVENTS_NAME, handleContentLabEvents);

    // Render the ContentLab widget component only on desktop layout
    return (
        !isMobile && (
            <UnisphereWidget
                ref={contentLabWidgetRef}
                widgetName="unisphere.widget.content-lab"
                context="application"
                settings={contentLabSettings}
            />
        )
    );
};

export default ContentLabCta;
