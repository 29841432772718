import StyledIllustration from "./StyledIllustration";

interface NoResultsIllustrationProps {
    width?: number;
    height?: number;
}

export const NoResultsIllustration = (props: NoResultsIllustrationProps) => {
    const { width = 200, height = 200 } = props;

    return (
        <StyledIllustration
            fileName={"NoResults.svg"}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 200 200"
        >
            <rect
                x="97.1936"
                y="74"
                width="71.3871"
                height="78.3865"
                rx="5.33333"
                transform="rotate(10 97.1936 74)"
                className="Tone1"
                fill="#333333"
            />
            <rect
                x="45"
                y="88.3335"
                width="108.667"
                height="74"
                rx="4.33333"
                className="Tone8 sTone1"
                fill="white"
                stroke="#333333"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M93.7845 40.3515C95.1384 34.2667 101.169 30.4315 107.253 31.7853V31.7853C113.338 33.1391 117.173 39.1693 115.82 45.2541V45.2541C114.466 51.3389 108.436 55.1742 102.351 53.8204V53.8204C96.266 52.4666 92.4307 46.4364 93.7845 40.3515V40.3515Z"
                className="Primary"
                fill="#006EFA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M105.63 40.5009C105.63 40.5009 107.836 38.3611 109.817 39.2558C111.798 40.1505 112.3 43.0576 110.418 44.9204C108.37 47.5334 104.679 48.1642 103.662 48.3026C103.556 48.3171 103.43 48.289 103.306 48.217C100.743 46.7286 99.4718 44.3238 98.8159 42.339C98.8159 42.339 97.8949 38.6899 100.965 37.6842C104.036 36.6785 105.63 40.5009 105.63 40.5009Z"
                className="Tone8"
                fill="white"
            />
            <path
                d="M133.687 84.2734L125.381 98.3434C124.426 99.9623 122.339 100.5 120.72 99.5442V99.5442C119.101 98.5885 118.563 96.5014 119.519 94.8825L129.382 78.1744C131.198 75.0985 135.164 74.077 138.24 75.8928V75.8928C141.315 77.7086 142.337 81.6741 140.521 84.75L139.831 85.9194"
                className="sPrimary"
                stroke="#006EFA"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M32 119.333C32 116.388 34.3878 114 37.3333 114H107.333C110.279 114 112.667 116.388 112.667 119.333V143V166.667C112.667 169.612 110.279 172 107.333 172H37.3333C34.3878 172 32 169.612 32 166.667V119.333Z"
                className="Primary"
                fill="#006EFA"
            />
            <path d="M44 162H100.667" className="sTone8" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <circle
                cx="72.3537"
                cy="140.015"
                r="12"
                transform="rotate(-5.3899 72.3537 140.015)"
                className="Tone8"
                fill="white"
            />
            <path
                d="M77.0203 138.86C77.9092 139.373 77.9092 140.656 77.0203 141.17L71.0203 144.634C70.1314 145.147 69.0203 144.505 69.0203 143.479L69.0203 136.551C69.0203 135.524 70.1314 134.883 71.0203 135.396L77.0203 138.86Z"
                className="Tone1"
                fill="#333333"
            />
            <rect
                width="28"
                height="14.6667"
                rx="7.33333"
                transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 64.7395 75.1987)"
                className="Tone1"
                fill="#333333"
            />
            <circle
                cx="2"
                cy="2"
                r="2"
                transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 68.3821 68.5942)"
                className="Tone8"
                fill="white"
            />
            <circle
                cx="2"
                cy="2"
                r="2"
                transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 74.7866 66.7427)"
                className="Tone8"
                fill="white"
            />
            <circle
                cx="2"
                cy="2"
                r="2"
                transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 81.1912 64.8916)"
                className="Tone8"
                fill="white"
            />
        </StyledIllustration>
    );
};
