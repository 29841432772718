import { forwardRef, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel, StyledFormHelperText } from "./CommonStyles";
import { MenuItem, Select } from "@kaltura/ds-react-components";
import { LabelledValue } from "@kaltura/mediaspace-shared-types";

export interface SelectFieldProps {
    className?: string;
    id: string;
    label: string;
    description?: string;
    value?: string;
    required?: boolean;
    options: LabelledValue[];
    fontWeightBold?: boolean;
    descriptionTooltip?: boolean;
    fullWidth?: boolean;
    /**
     * optional onChange callback
     */
    onChange?: (value: string) => void;
    additionalInfo?: string;
}

/**
 * date field for edit entry form
 */
export const SelectField = forwardRef<any, SelectFieldProps>(
    (
        {
            className,
            id,
            label,
            description,
            value,
            options: opts,
            fontWeightBold,
            descriptionTooltip = true,
            required = false,
            fullWidth,
            onChange,
            additionalInfo,
        },
        ref
    ) => {
        const { control } = useFormContext();

        const options = useMemo(
            () =>
                opts.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                    </MenuItem>
                )),
            [opts]
        );

        const handleChange = (value: string) => {
            onChange?.(value);
        };

        return (
            <Container className={className}>
                <StyledFormLabel
                    htmlFor={id}
                    text={label}
                    description={descriptionTooltip ? description : undefined}
                    required={required}
                    fontWeightBold={fontWeightBold}
                    additionalInfo={additionalInfo}
                />
                {!descriptionTooltip && description && (
                    <StyledFormHelperText id={`${id}-description`}>{description}</StyledFormHelperText>
                )}
                <Controller
                    control={control}
                    name={id as "dummy"}
                    defaultValue={value ?? ""}
                    rules={{ required: required }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                            id={id}
                            value={value}
                            inputRef={ref}
                            onChange={(event) => {
                                onChange(event);
                                handleChange(event.target.value);
                            }}
                            onBlur={onBlur}
                            aria-describedby={!descriptionTooltip && description ? `${id}-description` : undefined}
                            fullWidth={fullWidth}
                        >
                            {options}
                        </Select>
                    )}
                />
            </Container>
        );
    }
);
