import StyledIllustration from "../styled-illustration/StyledIllustration";

export const ErrorIllustration = ({ size = 300 }) => {
    return (
        <StyledIllustration
            fileName={"Error.svg"}
            width={size}
            height={size}
            viewBox="0 0 300 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="205" y="187" width="45" height="53" rx="8" className="Primary" fill="#006EFA" />
            <rect x="54" y="192" width="54" height="48" rx="8" className="Tone1" fill="#333333" />
            <path d="M72 206H93" className="sTone8" stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M63 216H87" className="sTone8" stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M75 226H93" className="sTone8" stroke="white" stroke-width="2" stroke-linecap="round" />
            <rect
                x="125"
                y="209"
                width="63"
                height="32"
                rx="8"
                className="Tone8 sTone1"
                fill="white"
                stroke="#333333"
                stroke-width="2"
            />
            <path
                d="M192.524 91.7757C194.574 89.6744 198.114 90.4232 199.137 93.1745L210.014 122.423C211.111 125.372 208.531 128.374 205.451 127.734L172.686 120.923C169.594 120.28 168.432 116.474 170.637 114.213L192.524 91.7757Z"
                className="Tone1"
                fill="#333333"
            />
            <rect x="124" y="134" width="22" height="21" rx="4" className="Tone1" fill="#333333" />
            <rect
                x="181.633"
                y="153.699"
                width="44"
                height="43.8572"
                rx="8"
                transform="rotate(36 181.633 153.699)"
                className="Tone1"
                fill="#333333"
            />
            <path
                d="M173.419 185.773L200.73 181.992"
                className="sTone8"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M189.496 197.454L184.653 170.311"
                className="sTone8"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
            />
            <rect
                x="181.5"
                y="140.5"
                width="40"
                height="40"
                rx="20"
                transform="rotate(-28.5 181.5 140.5)"
                className="Primary"
                fill="#006EFA"
            />
            <circle
                cx="121.21"
                cy="182.21"
                r="28"
                transform="rotate(-15 121.21 182.21)"
                className="Primary"
                fill="#006EFA"
            />
            <path
                d="M131.918 177.547C133.405 177.946 133.903 179.805 132.814 180.893L119.894 193.814C118.805 194.902 116.946 194.404 116.548 192.917L111.819 175.268C111.42 173.781 112.781 172.42 114.268 172.818L131.918 177.547Z"
                className="Tone8"
                fill="white"
            />
            <rect
                x="130.436"
                y="101.345"
                width="70"
                height="22"
                rx="7"
                transform="rotate(27 130.436 101.345)"
                className="Tone8 sTone1"
                fill="white"
                stroke="#333333"
                stroke-width="2"
            />
            <path d="M24 241H276" className="sTone1" stroke="#333333" stroke-width="2" stroke-linecap="round" />
        </StyledIllustration>
    );
};
