import styled from "@emotion/styled";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, Tooltip, Typography } from "@kaltura/ds-react-components";
import { CategoryIcon } from "./../category-icon/CategoryIcon";
import { KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { CategoryType } from "../../../../../../utils";
import { useContext } from "react";
import { ConfigContext } from "@kaltura/mediaspace-shared-data-kms-config";

export interface CategoryCheckboxProps {
    category: KmsTypePublishCategory;
    categoryType: CategoryType.channel | CategoryType.gallery;
    tooltipText: string;
    disabled?: boolean;
    depth?: number;
    isChecked: (categoryId: number) => boolean;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    isBulk?: boolean;
}

const StyledFormControlLabel = styled(
    FormControlLabel,
    {
        shouldForwardProp(propName: PropertyKey) {
            return propName !== "depth";
        },
    }
)<{ depth?: number }>(({ theme, depth = 1 }) => ({
    // add left margin by depth - for nested galleries display
    // (18px (checkbox) + 8px (right margin)) / 8px (spacing) = 3.25
    marginLeft: theme.spacing(2 + 3.25 * (depth - 1)),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    svg: {
        width: 16,
        height: 16,
        marginRight: theme.spacing(0.5),
        verticalAlign: "middle",
    },
}));

const StyledName = styled("span")({
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
});

/**
 * category checkbox
 */
export const CategoryCheckbox = (props: CategoryCheckboxProps) => {
    const {
        isChecked,
        onCategoryChange,
        category,
        tooltipText,
        disabled = false,
        depth = 1,
        categoryType,
        isBulk,
    } = props;

    const configContext = useContext(ConfigContext);
    const isKaf = configContext.application?.isKAF;

    return (
        <StyledFormControlLabel
            depth={depth}
            control={
                <StyledCheckbox
                    value={category.id}
                    disabled={disabled}
                    checked={isChecked(category.id)}
                    onChange={(event, checked) => onCategoryChange(category.id, checked)}
                />
            }
            label={
                <StyledLabel variant="body2Highlight">
                    {!isKaf && <CategoryIcon category={category} categoryType={categoryType} isBulk={isBulk} />}
                    <Tooltip title={tooltipText}>
                        <StyledName>{category.name}</StyledName>
                    </Tooltip>
                </StyledLabel>
            }
        ></StyledFormControlLabel>
    );
};
