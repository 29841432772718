import { ESupportedExtendedActions, Richtext, RichTextProps } from "@kaltura/ds-react-richtext";
import { forwardRef, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface RichtextFieldProps extends RichTextProps {
    id: string;
    label: string;
    description?: string;
    value?: string;
    additionalInfo?: string;
    fontWeightBold?: boolean;
}

/**
 * richtext field for edit entry form
 */
export const RichtextField = forwardRef<any, RichtextFieldProps>(
    ({ id, label, description, value, defaultValue, additionalInfo, fontWeightBold, ...rest }, ref) => {
        const { control } = useFormContext();
        const ariaLabeledRichTextActions = useMemo(
            () => [
                {
                    action: ESupportedExtendedActions.Bold,
                    ariaLabel: translate("Bold"),
                },
                {
                    action: ESupportedExtendedActions.Italic,
                    ariaLabel: translate("Italic"),
                },
                {
                    action: ESupportedExtendedActions.Underline,
                    ariaLabel: translate("Underline"),
                },
                {
                    action: ESupportedExtendedActions.BulletList,
                    ariaLabel: translate("Unordered List"),
                },
                {
                    action: ESupportedExtendedActions.OrderedList,
                    ariaLabel: translate("Ordered List"),
                },
                {
                    action: ESupportedExtendedActions.Image,
                    ariaLabel: translate("Insert Image"),
                },
                {
                    action: ESupportedExtendedActions.Link,
                    ariaLabel: translate("Insert Link"),
                },
            ],
            []
        );

        return (
            <Container>
                <StyledFormLabel
                    htmlFor={id}
                    id={`label-${id}`}
                    text={label}
                    description={description}
                    additionalInfo={additionalInfo}
                    fontWeightBold={fontWeightBold}
                />
                <Controller
                    control={control}
                    name={id as "dummy"}
                    defaultValue={value}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                    }) => (
                        <Richtext
                            {...rest}
                            ariaLabelledBy={`label-${id}`}
                            supportedActions={ariaLabeledRichTextActions}
                            value={value}
                            ref={ref}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    )}
                />
            </Container>
        );
    }
);
