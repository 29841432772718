import React, { ReactNode } from "react";
import { styled } from "@mui/material";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { Box, Skeleton, useTheme } from "@kaltura/mediaspace-shared-styled";
import { UserThumbnail, UserThumbnailSkeleton } from "../user-thumbnail/UserThumbnail";
import { fullWidthFlexItemStyles, HtmlReactParser, stripTags, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { Typography } from "@kaltura/ds-react-components";
import { Truncate, TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import { UserName } from "../user-name/UserName";
import { useElementSizeObserver } from "@kaltura/mediaspace-shared-hooks";

type UserCardSize = "big" | "small";

export interface UserCardProps {
    user: KmsTypePresenter;
    size: UserCardSize;
    /**
     * flag to indicate show more button should be
     * displayed when presenter bio is truncated
     * default is false (shows ellipsis)
     */
    showMore?: boolean;
    /**
     * element (CTA) to render at the bottom
     * of the user card
     */
    showMoreElement: ReactNode;
    isLive?: boolean;
    /**
     * optional id to use on the name for a11y purposes -
     * when its used as the label for another component (presenter modal)
     */
    nameId?: string;
}

export const UserCard = ({ user, size, showMore = false, showMoreElement, isLive, nameId }: UserCardProps) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
    const baseClassName = "kms-ds-user-card";
    let bioNumberOfLines = size === "big" ? 5 : isXs ? 1 : 3;

    const { elementRef: nameRef, totalLines: nameTotalLines } = useElementSizeObserver<HTMLDivElement>();
    const { elementRef: titleRef, totalLines: titleTotalLines } = useElementSizeObserver<HTMLDivElement>();

    /**
     * Calculate the number of lines displayed in the user's bio
     * based on the number of lines occupied by the user's name and title.
     * The intent is to ensure that the bio
     * does not overflow and is displayed correctly within the available space.
     */
    if (size !== "big" && nameTotalLines && titleTotalLines && user.bio) {
        if (bioNumberOfLines > 1) {
            if (nameTotalLines > 1) {
                bioNumberOfLines -= nameTotalLines - 1;
            }
            if (titleTotalLines > 1) {
                bioNumberOfLines -= titleTotalLines - 1;
            }
            bioNumberOfLines = Math.max(bioNumberOfLines, 1);
        }
    }

    return (
        <StyledContainer size={size} className={baseClassName}>
            <StyledThumbnailContainer size={size} className={`${baseClassName}-thumbnail`}>
                <UserThumbnail {...user} />
            </StyledThumbnailContainer>

            <StyledDetailsContainer size={size} className={`${baseClassName}-details`}>
                <StyledName
                    ref={nameRef}
                    component={"h3"}
                    id={nameId}
                    name={user.name}
                    size={size}
                    isLive={isLive}
                    lines={size === "small" ? 2 : "unlimited"}
                    className={`${baseClassName}-details-name`}
                />

                {user.title && (
                    <StyledTitle
                        ref={titleRef}
                        variant={"body1"}
                        size={size}
                        className={`${baseClassName}-details-title`}
                    >
                        {size === "small" ? (
                            <TruncateWithTooltip text={HtmlReactParser(user.title)} lines={2}>
                                <span>{HtmlReactParser(user.title)}</span>
                            </TruncateWithTooltip>
                        ) : (
                            <span>{HtmlReactParser(user.title)}</span>
                        )}
                    </StyledTitle>
                )}

                {user.bio && (
                    <StyledBio variant={"body1"} component={"div"} className={`${baseClassName}-details-bio`}>
                        <Truncate lines={bioNumberOfLines} showMore={showMore}>
                            {HtmlReactParser(user.bio)}
                        </Truncate>
                    </StyledBio>
                )}

                <StyledShowMore size={size} className={`${baseClassName}-details-more`}>
                    {showMoreElement}
                </StyledShowMore>
            </StyledDetailsContainer>
        </StyledContainer>
    );
};

export const UserCardSkeleton = () => {
    return (
        <StyledContainer size={"big"}>
            <StyledThumbnailContainer size={"big"}>
                <UserThumbnailSkeleton />
            </StyledThumbnailContainer>

            <StyledDetailsContainer size={"big"}>
                <StyledNameSkeleton variant={"rectangular"} />

                <StyledTitleSkeleton variant={"rectangular"} />

                <StyledBioSkeletonLine1 variant={"rectangular"} />
                <StyledBioSkeletonLine2 variant={"rectangular"} />

                <StyledShowMoreSkeleton variant={"rectangular"} />
            </StyledDetailsContainer>
        </StyledContainer>
    );
};

const skipSizeProp = {
    shouldForwardProp(propName: PropertyKey) {
        return propName !== "size";
    },
};

const StyledContainer = styled(
    Box,
    skipSizeProp
)<{ size: UserCardSize }>(({ theme, size }) => ({
    display: "flex",
    gap: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
        flexDirection: size === "big" ? "column" : "row",
        gap: theme.spacing(size === "big" ? 3 : 2),
        alignItems: size === "big" ? "center" : undefined,
    },
}));

const StyledThumbnailContainer = styled(
    Box,
    skipSizeProp
)<{ size: UserCardSize }>(({ theme, size }) => ({
    width: size === "big" ? 243 : 170,
    [theme.breakpoints.down("sm")]: {
        width: size === "big" ? 243 : 114,
    },
}));

const StyledDetailsContainer = styled(
    Box,
    skipSizeProp
)<{ size: UserCardSize }>(({ theme, size }) => [
    fullWidthFlexItemStyles,
    size === "big" && {
        [theme.breakpoints.down("sm")]: {
            // The parent flex has "flex-direction: column" style on mobile,
            // so ensure that the element takes the whole width and doesn't overflow the container
            width: "100%",
        },
    },
]);

const StyledName = styled(UserName)(({ theme, size }) => ({
    [theme.breakpoints.down("sm")]: {
        justifyContent: size === "big" ? "center" : undefined,
    },
}));

const StyledNameSkeleton = styled(Skeleton)(({ theme }) => ({
    maxWidth: 200,
    height: theme.spacing(4),
    borderRadius: theme.kaltura.shape.roundness1,
}));

const StyledTitle = styled(
    Typography,
    skipSizeProp
)<{ size: UserCardSize }>(({ theme, size }) => ({
    color: theme.kaltura.palette.tone2,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
        textAlign: size === "big" ? "center" : undefined,
    },
}));

const StyledTitleSkeleton = styled(Skeleton)(({ theme }) => ({
    marginTop: theme.spacing(1),
    maxWidth: 120,
    height: theme.spacing(2),
    borderRadius: theme.kaltura.shape.roundness1,
}));

const StyledBio = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
    marginTop: theme.spacing(1.5),
}));

const StyledBioSkeletonLine1 = styled(Skeleton)(({ theme }) => ({
    marginTop: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.kaltura.shape.roundness1,
}));

const StyledBioSkeletonLine2 = styled(StyledBioSkeletonLine1)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledShowMore = styled(
    Box,
    skipSizeProp
)<{ size: UserCardSize }>(({ theme, size }) => ({
    marginTop: theme.spacing(size === "big" ? 3 : 1),
}));

const StyledShowMoreSkeleton = styled(Skeleton)(({ theme }) => ({
    marginTop: theme.spacing(2),
    // The actual size of the "See profile page" button
    width: 147,
    height: theme.spacing(5),
    borderRadius: theme.kaltura.shape.roundness1,
}));
