import { ReactElement } from "react";

export enum AddToCalendarOptionsType {
    GOOGLE = "google",
    OUTLOOK = "outlook",
    EMAIL = "email",
}

export type CalendarOption = {
    label: string;
    type: AddToCalendarOptionsType;
    icon: ReactElement;
};
