import styled from "@emotion/styled";
import { LabelledValue } from "@kaltura/mediaspace-shared-types";
import React, { forwardRef, HTMLAttributes, memo, SyntheticEvent, useState } from "react";
import { AutoComplete, Checkbox } from "@kaltura/ds-react-components";
import { FormLabel, FormLabelProps } from "../form-label/FormLabel";

export interface MultiSelectProps {
    id?: string;
    value: LabelledValue[];
    onChange: (value: LabelledValue[]) => void;
    onBlur: () => void;
    error?: boolean;
    options: LabelledValue[];
}

const StyledElementWrap = styled.div(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
}));

const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(
    ({ id, value, onChange, onBlur, error, options }: MultiSelectProps, ref) => {
        const [autoCompleteValue, setAutoCompleteValue] = useState<LabelledValue[]>(value);

        const MemoizedFormLabel = memo((props: FormLabelProps) => <FormLabel {...props} />);
        const renderOption = (
            props: HTMLAttributes<HTMLLIElement>,
            option: LabelledValue,
            {
                selected,
            }: {
                selected: boolean;
            }
        ) => {
            return (
                <li {...props}>
                    <StyledElementWrap>
                        <Checkbox title={option.label} id={`${id}_${option.value}`} checked={selected} />
                        <MemoizedFormLabel text={option.label} htmlFor={`${id}_${option.value}`} />
                    </StyledElementWrap>
                </li>
            );
        };

        /* override kaltura-ds's AutoComplete change the use of "label" to "title": */
        const getAutocompleteOptionLabel = (option: LabelledValue) => option.label;
        const isAutocompleteOptionEqualToValue = (option: LabelledValue, value: LabelledValue) =>
            option.label === value.label;

        const handleSelectedChange = (event: SyntheticEvent<Element, Event>, value: LabelledValue[]) => {
            setAutoCompleteValue(value);
            onChange(value);
        };

        return (
            <AutoComplete
                ref={ref}
                id={id}
                value={autoCompleteValue}
                options={options}
                renderOption={renderOption}
                getOptionLabel={getAutocompleteOptionLabel}
                isOptionEqualToValue={isAutocompleteOptionEqualToValue}
                onChange={handleSelectedChange}
                multiple={true}
                fullWidth={true}
                onBlur={onBlur}
            />
        );
    }
);

export default MultiSelect;
