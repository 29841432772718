import { ButtonClickAnalyticsType, WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { translate, WebcastStatus } from "@kaltura/mediaspace-shared-utils";
import { Play24Icon } from "@kaltura/ds-react-icons";
import MailMenu from "./mail-menu/MailMenu";
import styled from "@emotion/styled";
import { Button, Tooltip } from "@kaltura/ds-react-components";
import { ButtonTextWrapper } from "./styled-components";
import { Config } from "@kaltura/mediaspace-shared-data-kms-config";
import { RsvpButton, RsvpStatus } from "@kaltura/mediaspace-shared-entry";

const JoinButton = styled(Button)({
    float: "right",
});

/**
 * get call-to-action button per given live-status
 * @param status
 * @param entryId
 * @param entry
 * @param vodCallback
 * @param liveCallback
 * @param sendButtonAnalytics
 * @param config
 * @param disabledMode
 * @param categoryId
 * @param rsvpStatus
 * @param onRsvpChange
 */
export const getCallToAction = (
    status: WebcastStatus,
    entryId: string,
    entry: WebcastEntry,
    vodCallback: (entryId: string, entryName: string, categoryId?: number) => void,
    liveCallback: (entryId: string, entryName: string, categoryId?: number) => void,
    sendButtonAnalytics: (actionName: string, buttonType: number) => void,
    config: Config,
    disabledMode?: boolean,
    categoryId?: number,
    rsvpStatus?: RsvpStatus,
    onRsvpChange?: (status: RsvpStatus | undefined) => void
) => {
    let callback: () => void;
    switch (status) {
        case WebcastStatus.past: {
            if (!entry.recordedEntryId) {
                return null;
            }

            callback = () => {
                sendButtonAnalytics("Session card - Watch VOD", ButtonClickAnalyticsType.LINK);
                vodCallback(entryId, entry.name, categoryId);
                return true;
            };
            return (
                <Tooltip title={translate("Watch - %1", [entry.name])} placement={"bottom"}>
                    <JoinButton
                        color={"primary"}
                        disabled={disabledMode}
                        onClick={callback}
                        startIcon={<Play24Icon />}
                        className={"kms-ds-event-list-item-button-watch"}
                        size={"large"}
                        aria-label={translate("Watch Button - %1", [entry.name])}
                    >
                        <ButtonTextWrapper>{translate("Watch")}</ButtonTextWrapper>
                    </JoinButton>
                </Tooltip>
            );
        }
        case WebcastStatus.live: {
            callback = () => {
                sendButtonAnalytics("Session card - Join Now", ButtonClickAnalyticsType.LINK);
                liveCallback(entryId, entry.name, categoryId);
                return true;
            };
            return (
                <Tooltip title={translate("Join Now - %1", [entry.name])} placement={"bottom"}>
                    <JoinButton
                        color={"danger"}
                        disabled={disabledMode}
                        onClick={callback}
                        startIcon={<Play24Icon />}
                        className={"kms-ds-event-list-item-button-join"}
                        size={"large"}
                        aria-label={translate("Join Button - %1", [entry.name])}
                    >
                        <ButtonTextWrapper>{translate("Join Now")}</ButtonTextWrapper>
                    </JoinButton>
                </Tooltip>
            );
        }
        case WebcastStatus.future: {
            return (
                <div>
                    {config.rsvp?.isCrossSite ? (
                        <RsvpButton
                            entry={entry}
                            size={"large"}
                            allowResendPostRsvp={config.application.isLoggedIn}
                            analyticsValue={{ location: "Session card" }}
                            isLoading={rsvpStatus === undefined}
                            initialStatus={rsvpStatus}
                            onChange={onRsvpChange}
                            onToggleClicked={() => onRsvpChange?.(undefined)}
                        />
                    ) : (
                        <MailMenu entryId={entryId} categoryId={categoryId} />
                    )}
                </div>
            );
        }
        default:
            return null;
    }
};
