/**
 * custom hook to run a custom script.
 */

import { useEffect } from "react";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";

export function useScript(scriptTxt?: string) {
    const {
        application: { cspNonce },
    } = useConfig();

    useEffect(() => {
        if (!scriptTxt) {
            return;
        }
        const script = document.createElement("script");
        script.innerText = scriptTxt;
        script.async = true;
        script.setAttribute("nonce", cspNonce);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [scriptTxt, cspNonce]);
}
