import { cdnUrl, formatters, translate } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import { alpha, Box, composeClasses } from "@kaltura/mediaspace-shared-styled";
import { SimpleThumbnailClasses, getSimpleThumbnailClass } from "./simpleThumbnailClasses";
import clsx from "clsx";
import { useState } from "react";
import { Playlist24Icon } from "@kaltura/ds-react-icons";

export interface ThumbnailProps {
    classes?: Partial<SimpleThumbnailClasses>;
    thumbnailUrl?: string;
    name: string;
    duration?: number;
    isLive?: boolean;
    isPlaylist?: boolean;
    roundCorners?: boolean;
    durationVariant?: "tight" | "normal";
}

const slots = {
    root: ["root"],
    image: ["image"],
    indicator: ["indicator"],
    live: ["live"],
};

const useUtilityClasses = (styleProps: ThumbnailProps) => {
    const { classes } = styleProps;

    return composeClasses(slots, getSimpleThumbnailClass, classes);
};

const ThumbnailContainer = styled(
    Box,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "roundCorners";
        },
    }
)<{ roundCorners: boolean }>(({ theme, roundCorners }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: theme.kaltura.palette.tone6,
    borderRadius: roundCorners ? theme.kaltura.shape.roundness1 : 0,
    overflow: "hidden",
    position: "relative",
}));

const StyledImage = styled("img")({
    width: "100%",
    height: "100%",
    verticalAlign: "middle",
});

const Live = styled("div")(({ theme }) => ({
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: theme.kaltura.palette.danger.main,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1,
}));

const Duration = styled(
    "div",
    { shouldForwardProp: (prop) => prop !== "variant" }
)<{ variant: "tight" | "normal" }>(({ theme, variant }) => ({
    position: "absolute",
    bottom: variant === "tight" ? theme.spacing(0.5) : theme.spacing(1),
    left: variant === "tight" ? theme.spacing(0.5) : theme.spacing(1),
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1,
}));

const StyledPlaylistIcon = styled(Playlist24Icon)(({ theme }) => ({
    position: "absolute",
    inset: 0,
    margin: "auto",
    color: theme.kaltura.palette.tone2,
}));

/**
 * thumbnail with duration or live indication
 * used in media-page (related entries)
 * used in global-search (media-card)
 */
export const SimpleThumbnail = (props: ThumbnailProps) => {
    const {
        thumbnailUrl,
        name,
        duration,
        isLive = false,
        isPlaylist = false,
        roundCorners = true,
        durationVariant = "normal",
    } = props;
    const classes = useUtilityClasses(props);
    // The thumbnail is in the error state from the start if the thumbnail URL is missing for non-playlist
    const [isErr, setIsErr] = useState(!thumbnailUrl && !isPlaylist);

    return (
        <ThumbnailContainer className={clsx(classes.root, isLive && classes.live)} roundCorners={roundCorners}>
            {isErr && (
                <StyledImage
                    src={cdnUrl + "/media/small_thumbnail.svg"}
                    alt={name}
                    className={classes.image}
                    onError={() => setIsErr(true)}
                />
            )}
            {!isErr && !!thumbnailUrl && (
                <StyledImage src={thumbnailUrl} alt={name} className={classes.image} onError={() => setIsErr(true)} />
            )}
            {isPlaylist && <StyledPlaylistIcon />}
            {isLive && <Live className={classes.indicator}>{translate("LIVE")}</Live>}
            {!isLive && !!duration && (
                <Duration className={clsx("kms-ds-thumbnail-duration", classes.indicator)} variant={durationVariant}>
                    {formatters.formatSecondsToTime(duration)}
                </Duration>
            )}
        </ThumbnailContainer>
    );
};
