import StyledIllustration from "./StyledIllustration";
import React from "react";

export const NoKeywordResultsIllustration = ({ size = 300 }) => {
    return (
        <StyledIllustration
            fileName={"NoKeywordResults.svg"}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={"none"}
            viewBox={`0 0 300 300`}
            className={"kms-ds-no-sessions-image"}
        >
            <path d="M143 92H203" stroke="#333333" strokeWidth="2" className="sTone1" strokeLinecap="round" />
            <circle cx="166" cy="92" r="8" className="Primary" fill="#006EFA" />
            <rect x="36" y="172" width="62" height="10" rx="5" className="Tone1" fill="#333333" />
            <rect x="108" y="172" width="30" height="10" rx="5" className="Primary" fill="#006EFA" />
            <rect x="36" y="202" width="30" height="10" rx="5" className="Tone1" fill="#333333" />
            <rect x="76" y="202" width="46" height="10" rx="5" className="Primary" fill="#006EFA" />
            <rect x="132" y="202" width="28" height="10" rx="5" className="Tone1" fill="#333333" />
            <rect x="36" y="232" width="62" height="10" rx="5" className="Tone1" fill="#333333" />
            <rect x="108" y="232" width="30" height="10" rx="5" className="Primary" fill="#006EFA" />
            <circle cx="153" cy="237" r="5" className="Tone1" fill="#333333" />
            <circle cx="173" cy="237" r="5" className="Tone1" fill="#333333" />
            <circle cx="193" cy="237" r="5" className="Tone1" fill="#333333" />
            <rect
                x="17"
                y="117"
                width="266"
                height="38"
                rx="19"
                className="Tone8 sTone1"
                fill="white"
                stroke="#333333"
                strokeWidth="2"
            />
            <path
                d="M41.8343 142.64C44.2737 142.637 46.7101 142.216 48.9934 141.368C51.9462 140.273 54.7333 138.388 56.1914 135.607C57.6526 132.828 58.9397 129.068 55.3297 128.702C51.8905 128.559 51.2539 133.353 52.6845 136.733C54.1151 140.112 57.8933 142.33 61.5492 141.99C64.0445 141.763 66.3323 140.546 68.4737 139.257C70.2332 138.195 70.9789 137.486 74.8542 135.018C76.0582 134.251 77.3863 133.586 78.8135 133.476C82.3187 133.205 84.8801 136.51 87.5188 138.836C91.2326 142.106 96.2786 143.821 101.209 143.491C103.768 143.617 109.945 142.521 114.176 137.126"
                stroke="#333333"
                className="sTone1"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M230 116C230 107.163 237.164 100 246 100V100C254.837 100 262 107.163 262 116V116C262 124.837 254.837 132 246 132V132C237.164 132 230 124.837 230 116V116Z"
                className="Tone1"
                fill="#333333"
            />
            <rect
                x="210.137"
                y="187.461"
                width="10"
                height="40"
                rx="5"
                transform="rotate(-45 210.137 187.461)"
                className="Tone1"
                fill="#333333"
            />
            <circle cx="195.74" cy="166" r="38" transform="rotate(-45 195.74 166)" className="Primary" fill="#006EFA" />
            <circle
                cx="195.554"
                cy="166"
                r="28.3828"
                transform="rotate(-45 195.554 166)"
                stroke="white"
                className="sTone8"
                strokeWidth="2"
            />
            <path
                d="M239.282 109.282L252.717 122.717"
                stroke="white"
                strokeWidth="2"
                className="sTone8"
                strokeLinecap="round"
            />
            <path
                d="M252.718 109.282L239.283 122.717"
                stroke="white"
                strokeWidth="2"
                className="sTone8"
                strokeLinecap="round"
            />
        </StyledIllustration>
    );
};
