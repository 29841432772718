import { useState } from "react";
import clsx from "clsx";
import { styled } from "@mui/material";
import { Box, Skeleton } from "@kaltura/mediaspace-shared-styled";
import { BackgroundThumbnail } from "@kaltura/mediaspace-shared-ui";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { Initials } from "@kaltura/ds-react-components";
import { useElementSizeObserver } from "@kaltura/mediaspace-shared-hooks";
import { Size } from "@kaltura/ds-react-components";

export interface UserThumbnailProps extends Omit<KmsTypePresenter, "id"> {
    className?: string;
    alt?: string;
    /**
     * size for the initials - if we want to use a custom size,
     * not calculated from the thumbnail size
     */
    initialsSize?: Size;
    /**
     * size for the whole thumbnail rectangle
     * support Size - small/meduim/large or custom size in px
     */
    size?: Size | number;
    onThumbnailResize?: (height: number) => void;
}

/**
 * user Thumbnail - user image if they have one, or initials if not.
 * user thumbnail can be : entry thumbnail(best), thumbAsset(if you must), non-kaltura(possible).
 *
 * size can be set as a preset - small, medium, large, px value, or by the container - when a fluid
 * size is called for.
 */
export const UserThumbnail = ({
    className,
    alt,
    initialsSize: initialsSizeProp,
    size,
    image,
    name,
    onThumbnailResize,
}: UserThumbnailProps) => {
    const [imageError, setImageError] = useState(false);

    const containerSizeTracker = useElementSizeObserver();

    onThumbnailResize?.(containerSizeTracker?.height ?? 0);

    const onImageError = () => {
        setImageError(true);
    };

    //TODO - large initials does not fit large user thumbnail
    const calculateInitialsSize =
        typeof size === "number" ? (size < 96 ? "medium" : "large") : size === "large" ? "medium" : size;
    const initialsSize = initialsSizeProp ?? calculateInitialsSize ?? "large";

    const baseClassName = "kms-ds-user-thumbnail";
    return (
        <StyledThumbnailContainer
            ref={containerSizeTracker.elementRef}
            className={clsx(baseClassName, className)}
            size={size}
        >
            {image && <img style={{ display: "none" }} src={image} onError={onImageError} alt="" />}
            {image && !imageError && (
                <StyledBackgroundThumbnail
                    thumbnailUrl={image}
                    size={size}
                    className={`${baseClassName}-background`}
                    alt={alt}
                />
            )}
            {(!image || imageError) && (
                <StyledInitialsContainer>
                    <StyledInitials
                        className={`${baseClassName}-initials`}
                        size={initialsSize}
                        thumbSize={size}
                        fullName={name}
                        aria-hidden="true"
                    />
                </StyledInitialsContainer>
            )}
        </StyledThumbnailContainer>
    );
};

/**
 * User Thumbnail Skeleton
 */
export const UserThumbnailSkeleton = () => {
    return (
        <StyledThumbnailContainer>
            <StyledSkeleton variant={"rectangular"} />
        </StyledThumbnailContainer>
    );
};

const StyledThumbnailContainer = styled(Box)<{ size?: Size | number }>(({ theme, size }) => ({
    position: "relative",
    borderRadius: theme.kaltura.shape.roundness2,
    // preset size thumbnails
    ...(
        size === "small" && {
            width: 24,
            height: 24,
            borderRadius: theme.kaltura.shape.roundness1,
        }
    ),
    ...(
        size === "medium" && {
            width: 36,
            height: 36,
            borderRadius: theme.kaltura.shape.roundness1,
        }
    ),
    ...(
        size === "large" && {
            width: 48,
            height: 48,
            borderRadius: theme.kaltura.shape.roundness1,
        }
    ),
    // custom sized thumbnail
    ...(
        typeof size === "number" && {
            width: size,
            height: size,
        }
    ),
    // background covering thumbnail
    ...(
        !size && {
            paddingTop: "100%",
            overflow: "hidden",
        }
    ),
}));

const StyledBackgroundThumbnail = styled(BackgroundThumbnail)<{ size?: Size | number }>(({ theme, size }) => ({
    borderRadius: theme.kaltura.shape.roundness2,
    ...(
        (size === "small" || size === "medium" || size === "large") && {
            borderRadius: theme.kaltura.shape.roundness1,
        }
    ),
}));

const StyledInitialsContainer = styled(Box)({
    position: "absolute",
    top: 0,
    width: "100%",
});

//TODO - large initials does not fit large user thumbnail
const StyledInitials = styled(Initials)<{ thumbSize?: Size | number }>(({ theme, thumbSize }) => ({
    borderRadius: theme.kaltura.shape.roundness2,
    ...(
        (thumbSize === "small" || thumbSize === "medium" || thumbSize === "large") && {
            borderRadius: theme.kaltura.shape.roundness1,
        }
    ),
}));

const StyledSkeleton = styled(Skeleton)({
    position: "absolute",
    inset: 0,
    height: "100%",
});
