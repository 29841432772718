import { useContext, useState } from "react";
import {
    KmsTypePublishUserCategories,
    KmsTypePublishCategories,
    KmsTypeCategoryMembership,
} from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { PendingAlert } from "../pending-alert/PendingAlert";
import { CategorySearchInput } from "../category-search/CategorySearchInput";
import { ChannelsTabFormContainer } from "./ChannelsTabFormContainer";
import { ConfigContext } from "@kaltura/mediaspace-shared-data-kms-config";

export interface ChannelsTabProps {
    entryIds: string[];
    channels: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    onPendingClick: () => void;
    isBulk?: boolean;
    disabledCategoryMembershipIds?: KmsTypeCategoryMembership[];
}

/**
 * publish modal channels tab
 */
export function ChannelsTab(props: ChannelsTabProps) {
    const { entryIds, channels, onPendingClick, isBulk } = props;

    const [keyword, setKeyword] = useState("");

    const configContext = useContext(ConfigContext);
    const isKaf = configContext.application?.isKAF;

    const handleSearch = (keyword: string) => {
        setKeyword(keyword);
    };

    const handleClearSearch = () => {
        setKeyword("");
    };

    return (
        <>
            <PendingAlert entryIds={entryIds} channels={channels} onPendingClick={onPendingClick} isBulk={isBulk} />

            {!isKaf && (
                <CategorySearchInput
                    placeholder={translate("Search channels")}
                    ariaLabel={translate("Enter text to search channels")}
                    onSearch={handleSearch}
                    onResetSearch={handleClearSearch}
                />
            )}

            <ChannelsTabFormContainer {...props} keyword={keyword} isBulk={isBulk} />
        </>
    );
}
